/**
 * COID Picker translations: it
 * Please keep this file sorted alphabetically
 */
export default {
    COID_INPUT_LABEL: 'COID',
    COID_INPUT_TEXT: 'Per favore inserire il tuo COID',
    COID_SUBMIT: 'Inviare',
    COID_TITLE: 'Benvenuta :username',
};
