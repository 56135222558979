import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import Loading from '../Loading';

import { getDisplayName } from '../../utils/hoc';

export const GET_REGISTRATION_STATUS = gql`
    {
        registrationstatus {
            id
            count
            order_number
            ceid
            coid
            company_name
            status
            paid
            send_email
            legal_form
            street
            zip
            city
            country
            vat
            language
            billing_company_name
            billing_legal_form
            billing_street
            billing_zip
            billing_city
            billing_country
            billing_vat
            certificate_valid
            certificate_next_audit_from
            certificate_next_audit_to
            certificate_next_audit_date
            certificate_cb_responsible
            number_employees
            contact_salutation
            contact_name
            contact_email
            contact_phone
            contact_salutation_opt
            contact_name_opt
            contact_phone_opt
            contact_email_opt
            company_operation_times
            company_blocking_period
            company_seasonal_blocking_period
            createdAt
            confirmedAt
            hasReport
        }
    }
`;

export default WrappedComponent => {
    const withRegistrationStatus = props => (
        <Query query={GET_REGISTRATION_STATUS}>
            {({ data, loading, error, client }) => {
                if (loading) {
                    return <Loading />;
                }
                if (error) {
                    return props.onError(client);
                }
                const { registrationstatus } = data;
                return <WrappedComponent data={registrationstatus} {...props} />;
            }}
        </Query>
    );
    withRegistrationStatus.displayName = `withRegistrationStatus(${getDisplayName(
        WrappedComponent
    )})`;
    withRegistrationStatus.propTypes = {
        onError: PropTypes.func.isRequired,
    };
    return withRegistrationStatus;
};
