/**
 * Login translations: it
 * Please keep this file sorted alphabetically
 */
export default {
    LOGIN_LABEL_PASSWORD: 'Password',
    LOGIN_LABEL_USERNAME: 'Indirizzo email',
    LOGIN_LOGOUT: 'Logout',
    LOGIN_SUBMIT: '	Firmare',
    LOGIN_TEXT:
        'Siamo lieti abbia deciso di ordinare un IFS Food Safety Check non annunciato. Si prega di utilizzare i dati LOGIN del database IFS per continuare.',
    LOGIN_TITLE: 'Benvenuti alla registrazione Food Safety Check.',
    LOGIN_WRONG_PASSWORD: 'Username o password errata',
};
