import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Element } from 'react-scroll';

import { getDisplayName } from '../../utils/hoc';

export default WrappedComponent => {
    const withFormControl = props => {
        const { required, error, identifier, label } = props;

        return (
            <FormControl margin="normal" required={required} fullWidth error={!!error}>
                <Element name={identifier} />
                <InputLabel htmlFor={identifier}>{label}</InputLabel>
                <WrappedComponent {...props} key={identifier} />
                {error ? (
                    <FormHelperText id="component-error-text">{error()}</FormHelperText>
                ) : (
                    undefined
                )}
            </FormControl>
        );
    };
    withFormControl.displayName = `withFormControl(${getDisplayName(WrappedComponent)})`;
    withFormControl.propTypes = {
        required: PropTypes.bool,
        error: PropTypes.func,
        identifier: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    };
    withFormControl.defaultProps = {
        error: null,
        required: false,
    };
    return withFormControl;
};
