export default {
    CERTIFICATE_AVAILABLE_CERTIFICATES: 'Available certificates',
    CERTIFICATE_CHANGE_BUTTON: 'Change',
    CERTIFICATE_CHANGE_SUBMIT: 'Change certificate',
    CERTIFICATE_NO_AVAILABLE_CERTIFICATES: 'No alternative certificates available',
    COLUMN_billing_city: 'Different billing address: City',
    COLUMN_billing_company_name: 'Different billing address: Company',
    COLUMN_billing_country: 'Different billing address: Country',
    COLUMN_billing_legal_form: 'Billing Legal Form',
    COLUMN_billing_street: 'Different billing address: Street, No.',
    COLUMN_billing_vat: 'Different billing address: VAT',
    COLUMN_billing_zip: 'Different billing address: Zip Code',
    COLUMN_ceid: 'CEID',
    COLUMN_certificate_cb_responsible: 'CB of the last audit',
    COLUMN_certificate_next_audit: 'Next IFS audit to be performed within the time period',
    COLUMN_certificate_next_audit_date: 'Next auditdate (Food Audit)',
    COLUMN_certificate_valid: 'Certificate valid until',
    COLUMN_city: 'City',
    COLUMN_coid: 'COID',
    COLUMN_company_blocking_period: 'Blocking times',
    COLUMN_company_name: 'Company',
    COLUMN_company_operation_times: 'Production times',
    COLUMN_company_seasonal_blocking_period: 'Seasonal blocking times',
    COLUMN_confirmedAt: 'Order confirmed',
    COLUMN_contact_email: 'E-Mail 1',
    COLUMN_contact_email_opt: 'E-Mail 2',
    COLUMN_contact_name: 'Contact Name 1',
    COLUMN_contact_name_opt: 'Contact Name 2',
    COLUMN_contact_phone: 'Tel 1',
    COLUMN_contact_phone_opt: 'Tel 2',
    COLUMN_contact_salutation: 'Form of address 1',
    COLUMN_contact_salutation_opt: 'Form of address 2',
    COLUMN_count: 'Order count F/W',
    COLUMN_country: 'Country',
    COLUMN_createdAt: 'Order date',
    COLUMN_hasReport: 'Report',
    COLUMN_language: 'Language of company',
    COLUMN_legal_form: 'Legal Form',
    COLUMN_number_employees: 'Number employees',
    COLUMN_order_number: 'Order number',
    COLUMN_paid: 'Paid',
    COLUMN_send_email: 'IFS FSC Registration Email sent',
    COLUMN_status: 'Status',
    COLUMN_street: 'Street, No.',
    COLUMN_vat: 'VAT',
    COLUMN_zip: 'Zip Code',
    REGISTRATION_STATUS_CONFIRM_CANCEL: 'Cancel',
    REGISTRATION_STATUS_CONFIRM_SUBMIT: 'Save',
    REGISTRATION_STATUS_CONFIRM_TEXT: 'Save changes?',
    REGISTRATION_STATUS_FILE_DELETE_BUTTON: 'Delete',
    REGISTRATION_STATUS_FILE_DELETE_CONFIRM: 'Do you want to delete the report?',
    REGISTRATION_STATUS_FILE_UPLOAD_BUTTON: 'Upload',
    REGISTRATION_STATUS_FILE_UPLOAD_CONFIRM: 'Do you want to upload ":filename"?',
    REGISTRATION_STATUS_SAVE_CHANGES: 'Save changes',
    REGISTRATION_STATUS_SAVE_CSV: 'Download CSV',
    STATUS_Cancelled: 'Cancelled',
    STATUS_CertViolation: 'On Hold',
    STATUS_Failed: 'Failed',
    STATUS_Passed: 'Passed',
    STATUS_PassedQuestions: 'Passed but',
    STATUS_Registered: 'Registered',
    STATUS_Rejected: 'Rejected',
};
