import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import { getAccessToken, logoutUser } from '../utils/auth';
import { routes } from '..';

const USER_ROLE = {
    USER: 'user',
    ADMIN: 'admin',
};

export const GET_USER = gql`
    {
        user {
            IsAdmin
        }
    }
`;

const LoginRedirect = from => (
    <Redirect
        to={{
            pathname: routes.login,
            state: { from },
        }}
    />
);

const PrivateRoute = ({ component: Component, userRole, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            if (!getAccessToken()) {
                return <LoginRedirect from={props.location} />;
            }
            return (
                <Query query={GET_USER}>
                    {({ data, loading, error, client }) => {
                        if (loading) {
                            return null;
                        }
                        if (error) {
                            logoutUser(client);
                            return <LoginRedirect from={props.location} />;
                        }

                        if (!data.user.IsAdmin && userRole === USER_ROLE.ADMIN) {
                            return <Redirect to={routes.register} />;
                        }

                        return <Component {...props} />;
                    }}
                </Query>
            );
        }}
    />
);

PrivateRoute.propTypes = {
    component: PropTypes.func.isRequired,
    location: PropTypes.shape({}),
    userRole: PropTypes.oneOf([USER_ROLE.USER, USER_ROLE.ADMIN]),
};
PrivateRoute.defaultProps = {
    location: undefined,
    userRole: USER_ROLE.USER,
};

export default PrivateRoute;
