import en from './en';
import de from './de';
import es from './es';
import fr from './fr';
import it from './it';

export default {
    en,
    de,
    es,
    fr,
    it,
};
