import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import Header from '../../components/Header';

import styles from './styles.scss';

// palette: https://colorhunt.co/palette/133026
// alternatives: 5487a3, e6b89c

const theme = createMuiTheme({
    palette: {
        primary: { main: '#5c7893' },
        secondary: { main: '#ece493' },
    },
    typography: {
        useNextVariants: true,
    },
});

const MainLayout = ({ children }) => (
    <Fragment>
        <div className={styles.main}>
            <MuiThemeProvider theme={theme}>
                <Header />
                <div className={styles.inner}>{children}</div>
            </MuiThemeProvider>
        </div>
    </Fragment>
);

MainLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default MainLayout;
