import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { withClientState } from 'apollo-link-state';

import config from './config';

import App from './app';

import { getAccessToken } from './app/utils/auth';
import './polyfills';

const cache = new InMemoryCache();

const httpLink = createHttpLink({
    uri: `${config.serverBase}${config.apiPath}`,
});

const authLink = setContext((_, { headers }) => {
    const token = getAccessToken();
    return {
        headers: {
            ...headers,
            'x-access-token': token || '',
        },
    };
});

const defaults = {
    coid: null,
};

const stateLink = withClientState({
    cache,
    defaults,
    resolvers: {}, // we dont us a mutation for now, if the applcation complexity rises consder adding those instead of using direct writes
});

export const client = new ApolloClient({
    link: authLink.concat(stateLink).concat(httpLink),
    cache,
});

render(
    <ApolloProvider client={client}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </ApolloProvider>,
    document.getElementById('index')
);
