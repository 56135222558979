import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import LoginForm from '../../components/LoginForm';
import { routes } from '../../index';

import { getAccessToken } from '../../utils/auth';

class Login extends React.Component {
    static propTypes = {
        location: PropTypes.shape({
            state: PropTypes.shape({
                from: PropTypes.shape({
                    pathname: PropTypes.string,
                }),
            }),
        }).isRequired,
    };

    state = {
        redirectToReferrer: false,
        pathname: routes.pickCoid,
    };

    login = isAdmin => {
        this.setState(() => ({
            redirectToReferrer: true,
            pathname: isAdmin ? routes.admin : routes.pickCoid,
        }));
    };

    render() {
        const { location } = this.props;
        const { pathname } = this.state;
        const { from } = location.state || { from: { pathname } };
        const { redirectToReferrer } = this.state;

        if (redirectToReferrer || getAccessToken()) {
            return <Redirect to={from} />;
        }

        return (
            <div className="page">
                <LoginForm onLogin={this.login} />
            </div>
        );
    }
}

export default Login;
