import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import filter from 'lodash/filter';
import Input from '../FormFields/Input';

import styles from './styles.scss';
import {
    validate,
    getNoCertificateError,
    getNoCompanyForCoidError,
    getNoCertificateWithoutRegistrationError,
} from './validate';
import withLocale from '../../hocs/withLocale';

export const GET_COMPANY = gql`
    query company($coid: Int!) {
        company(coid: $coid) {
            certificates {
                id
                hasRegistration
            }
        }
    }
`;

class CoidPicker extends Component {
    state = {
        coid: '',
        error: undefined,
        loading: false,
    };

    handleSubmit = event => {
        event.preventDefault();
        const { coid } = this.state;
        const { trans } = this.props;
        const error = validate({ coid }, trans);

        if (error) {
            this.setState({ error });
        } else {
            this.setState({ loading: true }, this.executeQuery);
        }
    };

    executeQuery = () => {
        const { coid } = this.state;
        const { onSubmit, trans, client } = this.props;
        const parsedCoid = parseInt(coid, 10);
        client
            .query({
                query: GET_COMPANY,
                fetchPolicy: 'no-cache',
                variables: { coid: parsedCoid },
            })
            .then(({ data: { company } }) => {
                const certificatesWithoutRegistration = filter(
                    company.certificates,
                    cert => !cert.hasRegistration
                );
                if (certificatesWithoutRegistration.length) {
                    onSubmit(parsedCoid);
                } else {
                    const error = company.certificates.length
                        ? getNoCertificateWithoutRegistrationError(coid, trans)
                        : getNoCertificateError(coid, trans);
                    this.setState({
                        error,
                        loading: false,
                    });
                }
            })
            .catch(() => {
                this.setState({
                    error: getNoCompanyForCoidError(coid, trans),
                    loading: false,
                });
            });
    };

    handleChange = event => {
        this.setState({
            coid: event.target.value,
        });
    };

    render() {
        const { user, trans } = this.props;
        const { coid, error, loading } = this.state;
        return (
            <Paper className={styles.coidPicker}>
                <Typography variant="h6" className={styles.title}>
                    <div>
                        {trans('COID_TITLE', { username: `${user.FirstName} ${user.SurName}` })}
                    </div>
                </Typography>
                <Typography variant="body1" className={styles.textBody}>
                    {trans('COID_INPUT_TEXT')}
                </Typography>
                <form onSubmit={this.handleSubmit}>
                    <Input
                        value={coid}
                        identifier="coid"
                        label={trans('COID_INPUT_LABEL')}
                        onChange={this.handleChange}
                        error={error}
                        required
                        autoFocus
                    />

                    <Button
                        disabled={loading}
                        className={styles.submitButton}
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={this.handleSubmit}
                    >
                        {loading ? <CircularProgress size={21} /> : trans('COID_SUBMIT')}
                    </Button>
                </form>

                <Typography variant="caption" className={styles.footNote}>
                    {trans('MANDATORY_FIELD')}
                </Typography>
            </Paper>
        );
    }
}

CoidPicker.propTypes = {
    client: PropTypes.shape({
        query: PropTypes.func.isRequired,
    }).isRequired,
    user: PropTypes.shape({
        FirstName: PropTypes.string.isRequired,
        SurName: PropTypes.string.isRequired,
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    trans: PropTypes.func.isRequired,
};

export default withApollo(withLocale(CoidPicker));
