import React, { PureComponent } from 'react';
import MaterialMenuItem from '@material-ui/core/MenuItem';

class MenuItem extends PureComponent {
    render() {
        return <MaterialMenuItem {...this.props} />;
    }
}

export default MenuItem;
