import moment from 'moment';
import Locales from '../i18n';

let _translations = {};

/**
 * gets a translation supporting pluralization
 * _param  {String} key       placeholder for the translated text
 * _param  {Object} data      map containing values to be injected
 * _return {String}
 */
export const trans = (key, data = {}) => {
    if (!_translations[key]) {
        return key;
    }

    const translation = _translations[key];

    if (!data) {
        return translation;
    }

    const o = data; // to avoid flow computed property error message

    return Object.keys(o).reduce(
        (res, dataKey) => res.replace(new RegExp(`:${dataKey}`, 'g'), String(o[dataKey])),
        translation
    );
};

/**
 * inits the language that is used
 * @param  {String} [lang='de'] the language key
 * @param  {Object} data        optional - used for testing - insert
 *                              translation object directly
 */
export const init = (lang = 'en', data, remember = true) => {
    moment.locale([lang, 'en']); // this will use the set language and en as fallback if the first language is not supported
    if (data) {
        _translations = data;
    } else {
        // Check browser locale, set or get locale from localstorage?
        _translations = Locales[lang];
    }
    if (remember && typeof localStorage !== 'undefined') {
        localStorage.ifsLocale = lang;
    }
};

export const getLocale = () => localStorage.ifsLocale;
