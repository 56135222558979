/**
 * Registerform translations: fr
 * Please keep this file sorted alphabetically
 */
export default {
    ifs_cashcarry2: 'IFS Wholesale 2',
    ifs_food6: 'IFS Food 6.1',
    ifs_food7: 'IFS Food 7',
    ifs_food8: 'IFS Food 8',
    REGISTER_ADDRESS_CITY: 'Ville',
    REGISTER_ADDRESS_COMPANYNAME: 'Nom',
    REGISTER_ADDRESS_COUNTRY: 'Pays',
    REGISTER_ADDRESS_LEGALFORM: 'Forme juridique',
    REGISTER_ADDRESS_STREET: 'Rue, No',
    REGISTER_ADDRESS_VAT: 'VAT (pour les pays européens)',
    REGISTER_ADDRESS_ZIP: 'Code postal',
    REGISTER_BILLINGADDRESS_COMPANY_NAME: 'Nom',
    REGISTER_BILLINGADDRESS_LEGALFORM: 'Forme juridique',
    REGISTER_BILLINGADDRESS_TITLE: 'Si applicable: adresse de facturation',
    REGISTER_CEID: 'Standard',
    REGISTER_CEID_TITLE:
        'Veuillez choisir le certificat IFS pour lequel un IFS Food Safety check va être réalisé.',
    REGISTER_CEID_VALID_TO: 'valide jusqu’en :date',
    REGISTER_COID_HINT:
        "Inscription à l'IFS Food Safety Check (il ne s'agit PAS d'un audit de certification IFS non annoncé).",
    REGISTER_COID_TITLE: 'Utiliser le COID suivant pour vous enregistrer',
    REGISTER_COID_TITLE_EXPLANATION:
        '(Sachez que votre enregistrement se réfère au certificat en cours de validité et que l’IFS Food Safety Check sera effectué pendant la durée de validité de ce certificat!)',
    REGISTER_COMPANY_BLOCKING_PERIOD: 'Option: horaires de non-production et période de blocage',
    REGISTER_COMPANY_BLOCKING_PERIOD_HELP:
        'La société peut mentionner 10 jours opérationnels comme période de blocage. Ces 10 jours opérationnels peuvent être divisés en 3 périodes maximum et doivent expliquer les raisons, telles que les visites de clients, les congés, pendant lesquelles aucun IFS Food Safety Check ne pourra être réalisé.',
    REGISTER_COMPANY_OPERATION_TIMES:
        'Horaires habituels de fonctionnement et de production du site à auditer',
    REGISTER_COMPANY_OPERATION_TIMES_DESC: '(par ex. lundi-mercredi de 9 h à 14 h, 16 h à 22 h)',
    REGISTER_COMPANY_OPERATION_TIMES_HELP:
        'Ces horaires seront transmis par IFS Management à l’auditeur de l’IFS Food Safety Check pour s’assurer que le check sera effectué aux horaires de fonctionnement et de production.',
    REGISTER_COMPANY_SEASONAL_BLOCKING_PERIOD: 'Production saisonnière, si applicable',
    REGISTER_COMPANYADDRESS_LANGUAGE: 'Langue de la société',
    REGISTER_COMPANYADDRESS_TITLE:
        'Nom et adresse du site pour lequel un IFS Food Safety check va être réalisé',
    REGISTER_CONFIRM: "Envoyer l'enregistrement",
    REGISTER_CONTACT_DESCRIPTION:
        'Personne à contacter, qui accompagnera l’auditeur pendant le Food Safety Check IFS',
    REGISTER_CONTACT_EMAIL: 'E-Mail',
    REGISTER_CONTACT_NAME: 'Prénom Nom',
    REGISTER_CONTACT_PERSON: 'Personne 1*',
    REGISTER_CONTACT_PERSON_OPT: 'Personne 2',
    REGISTER_CONTACT_PHONE: 'Tél',
    REGISTER_CONTACT_SALUTATION: 'Adresse',
    REGISTER_CONTACT_SEND_MAIL_TO:
        'Veuillez noter que la facture sera envoyée à l’adresse de la personne 1',
    REGISTER_FURTHER_DETAILS: 'Informations supplémentaires',
    REGISTER_INFORM_IF_CHANGES_1:
        'Veuillez nous informer des changements de dates à court-terme si aucune production ou aucun processus opérationnel n’a lieu dans votre société (par ex. journées de procédures de certification, arrêts de production dus à une maintenance/réparation/actions contre les nuisibles) par e-mail:',
    REGISTER_INFORM_IF_CHANGES_2: '.',
    REGISTER_NUMBER_EMPLOYEES: 'Nombre d’employés',
    REGISTER_ORDER_ACCEPT_COSTS:
        'Oui, je désire commander un IFS Food Safety Check et je suis conscient(e) des coûts liés à ma commande. *',
    REGISTER_ORDER_ACCEPT_GENERALTERMS:
        'Oui, j’accepte les Conditions Générales de Vente (disponible à : www.ifs-certification.com) *',
    REGISTER_ORDER_IFSFOODCHECK: 'Commande d’un IFS Food Safety Check',
    REGISTER_ORDER_INFORMATION_1:
        'Vous recevrez la facture après avoir complété le formulaire de commande en ligne et finalisé cette commande. La facture doit être payée en avance.',
    REGISTER_ORDER_INFORMATION_2:
        'L’IFS Food Safety Check ne s’applique qu’à une seule période de validité du certificat et la commande est pour un seul IFS Food Safety Check.',
    REGISTER_ORDER_INFORMATION_3: ' ',
    REGISTER_SALUTATION_FEMALE: 'Mme',
    REGISTER_SALUTATION_MALE: 'Monsieur',
    REGISTER_SUCCESS_ANOTHER_REGISTRATION_TEXT:
        'Voulez-vous commander un Food Safety Check pour un autre COID?',
    REGISTER_SUCCESS_BUTTON_BACK: 'Retour à la page d’accueil IFS',
    REGISTER_SUCCESS_BUTTON_REGISTER: 'Commande',
    REGISTER_SUCCESS_GREETING: 'Cordialement, l’équipe IFS',
    REGISTER_SUCCESS_INVOICE_TEXT:
        'Une facture anticipée sera envoyée à l’adresse e-mail de la personne de contact mentionnée. Veuillez régler la facture sous 14 jours',
    REGISTER_SUCCESS_ON_HOLD_TEXT:
        'Cependant, des différences ont été constatées par rapport aux conditions générales du Food Safety Check. Nous allons procéder à des vérifications et vous contacterons très prochainement.',
    REGISTER_SUCCESS_ON_HOLD_TITLE: 'Vos données ont bien été enregistrées.',
    REGISTER_SUCCESS_TITLE: 'Merci, votre commande pour le COID :coid a bien été enregistrée!',
    REGISTER_TITLE: 'Veuillez entrer les informations suivantes:',
};
