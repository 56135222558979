import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';

class SendEmailCheckbox extends Component {
    handleChange = event => {
        const { tableRow, onChange } = this.props;
        onChange('send_email', event.target.checked, tableRow.rowId);
    };

    render() {
        const {
            value,
            update,
            tableRow: { rowId },
        } = this.props;
        const currentValue = update && update.send_email !== undefined ? update.send_email : value;
        return (
            <Checkbox
                checked={currentValue}
                value="send_email"
                onChange={this.handleChange}
                color="default"
                disabled={value}
                data-test={`sendMailCheckbox-${rowId}`}
            />
        );
    }
}

SendEmailCheckbox.propTypes = {
    onChange: PropTypes.func.isRequired,
    tableRow: PropTypes.shape({
        rowId: PropTypes.number,
    }).isRequired,
    update: PropTypes.shape({
        paid: PropTypes.bool,
    }),
    value: PropTypes.bool.isRequired,
};

SendEmailCheckbox.defaultProps = {
    update: undefined,
};

export default SendEmailCheckbox;
