/**
 * Validation translations: it
 * Please keep this file sorted alphabetically
 */
export default {
    MANDATORY_FIELD: '* Campo obbligatorio',
    VALIDATE_ACCEPT_AGBS: 'Si prega di accettare le nostre condizioni generali di contratto.',
    VALIDATE_ACCEPT_COSTS: 'Si prega di confermare di accettare i costi.',
    VALIDATE_BILLING_ADDRESS_ALL_OR_NOTHING:
        'Indirizzo di fatturazione diverso deve essere compilato completamente o lasciato completamente vuoto.',
    VALIDATION_CERTIFICATES_HAVE_REGISTRATION:
        'Esiste già una registrazione per l’attuale certificato valido di questo COID.',
    VALIDATION_INVALID_EMAIL: 'Indirizzo e-mail non valido',
    VALIDATION_MUST_CONTAIN_LETTER: ':field deve contenere una lettera',
    VALIDATION_NO_CERTIFICATE: `Per questo COID non ci sono certificati valid.`,
    VALIDATION_NO_COMPANY_FOR_COID: ':field errato, inserire un COID valido.',
    VALIDATION_NO_EMAIL: 'Inserisci un indirizzo e-mail valido.',
    VALIDATION_NO_NUMBER: ':field deve essere un numero',
    VALIDATION_NO_POSTALCODE: ':field deve contenere un codice postale valido',
    VALIDATION_NOT_EMPTY: ':field è un campo obbligatorio',
};
