import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import Typography from '@material-ui/core/Typography';
// import styles from './styles.scss';

const HelpToolTip = ({ title, color }) => (
    // <div>hi there</div>;
    <Tooltip title={<Typography color="inherit">{title}</Typography>}>
        <HelpIcon color={color} />
    </Tooltip>
);

HelpToolTip.propTypes = {
    title: PropTypes.string.isRequired,
    color: PropTypes.string,
};
HelpToolTip.defaultProps = {
    color: 'primary',
};

export default HelpToolTip;
