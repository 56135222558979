/**
 * Validation translations: fr
 * Please keep this file sorted alphabetically
 */
export default {
    MANDATORY_FIELD: '* Champ obligatoire',
    VALIDATE_ACCEPT_AGBS: 'Veuillez accepter nos conditions générales de vente.',
    VALIDATE_ACCEPT_COSTS: 'Veuillez confirmer que vous acceptez les frais.',
    VALIDATE_BILLING_ADDRESS_ALL_OR_NOTHING:
        'L’adresse de facturation différente doit être complétée entièrement ou laissée complètement vide.',
    VALIDATION_CERTIFICATES_HAVE_REGISTRATION:
        'Ce certificat en cours de validité est déjà enregistré sous un COID.',
    VALIDATION_INVALID_EMAIL: 'Adresse électronique invalide',
    VALIDATION_MUST_CONTAIN_LETTER: ':field doit contenir une lettre',
    VALIDATION_NO_CERTIFICATE: 'Aucun certificat n’est valide pour ce COID',
    VALIDATION_NO_COMPANY_FOR_COID: ':field incorrect, veuillez entrer un COID valide ',
    VALIDATION_NO_EMAIL: 'Veuillez saisir une adresse e-mail valide.',
    VALIDATION_NO_NUMBER: ':field doit être un nombre',
    VALIDATION_NO_POSTALCODE: 'Le code postal doit être valide.',
    VALIDATION_NOT_EMPTY: ':field est un champ obligatoire',
};
