const pdfFileType = 'data:application/pdf;base64,';

export const fileToBase64 = (file, fileType = pdfFileType) =>
    new Promise(resolve => {
        const reader = new FileReader();
        reader.onload = event => {
            const { result } = event.target;
            resolve(result.substr(result.indexOf(fileType) + fileType.length));
        };
        reader.readAsDataURL(file);
    });
