import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';

class DropDown extends Component {
    state = {
        open: false,
    };

    clickAway = event => {
        // if our toggle button is clicked we dont want the clickaway to trigger too
        if (this.anchorEl.contains(event.target)) {
            return;
        }

        this.close();
    };

    close = () => {
        this.setState({ open: false });
    };

    toggle = () => {
        const { open } = this.state;

        this.setState({ open: !open });
    };

    render() {
        const { label, menuItems, className } = this.props;
        const { open } = this.state;
        return (
            <div className={className}>
                <Button
                    buttonRef={node => {
                        this.anchorEl = node;
                    }}
                    onClick={this.toggle}
                    data-test="dropDownToggleButton"
                >
                    {label}
                </Button>
                <Popper id="test" open={open} anchorEl={this.anchorEl} disablePortal>
                    <Paper>
                        <ClickAwayListener onClickAway={this.clickAway}>
                            <MenuList>
                                {menuItems.map(menuItem => (
                                    <MenuItem
                                        key={menuItem.label}
                                        onClick={() => {
                                            menuItem.onClick();
                                            this.close();
                                        }}
                                    >
                                        {menuItem.label}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Popper>
            </div>
        );
    }
}

DropDown.propTypes = {
    className: PropTypes.string,
    label: PropTypes.node.isRequired,
    menuItems: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            onClick: PropTypes.func.isRequired,
        })
    ).isRequired,
};
DropDown.defaultProps = {
    className: '',
};

export default DropDown;
