import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { withApollo } from 'react-apollo';

import queryString from 'query-string';

import { setAccessToken } from '../utils/auth';

const setParams = ({ coid, token }, client) => {
    if (token) {
        setAccessToken(token);
    }

    if (coid) {
        client.writeData({ data: { coid: parseInt(coid, 10) } });
    }
};

const QueryCapturer = ({ client, location: { pathname, search } }) => {
    if (search) {
        setParams(queryString.parse(search), client);
        return (
            <Redirect
                to={{
                    pathname,
                }}
            />
        );
    }

    return null;
};

QueryCapturer.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string.isRequired,
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    client: PropTypes.shape({
        writeData: PropTypes.func,
    }).isRequired, // the apollo client injected by the apollo provider
};

export default withApollo(QueryCapturer);
