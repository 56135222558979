/**
 * COID Picker translations: en
 * Please keep this file sorted alphabetically
 */
export default {
    COID_INPUT_LABEL: 'COID',
    COID_INPUT_TEXT: 'Please enter your COID',
    COID_SUBMIT: 'Submit',
    COID_TITLE: 'Welcome :username',
};
