import React from 'react';
import PropTypes from 'prop-types';

import { init, getLocale } from '../utils/locale';

export const LocaleContext = React.createContext();

class LocaleProvider extends React.Component {
    constructor(props) {
        super(props);
        const locale = props.fixedLocale || getLocale() || 'en';
        this.state = {
            locale,
        };
        init(locale, null, props.fixedLocale === undefined);
    }

    changeLocale = locale => {
        const { fixedLocale } = this.props;
        if (fixedLocale === undefined) {
            init(locale);
            this.setState({
                locale,
            });
        }
    };

    render() {
        const { children, fixedLocale } = this.props;
        const { locale } = this.state;
        return (
            <LocaleContext.Provider
                value={{
                    locale,
                    changeLocale: this.changeLocale,
                    hasFixedLocale: fixedLocale !== undefined,
                }}
            >
                {children}
            </LocaleContext.Provider>
        );
    }
}

LocaleProvider.propTypes = {
    children: PropTypes.node.isRequired,
    fixedLocale: PropTypes.string,
};

LocaleProvider.defaultProps = {
    fixedLocale: undefined,
};

export default LocaleProvider;
