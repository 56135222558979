import React, { Component } from 'react';
import PropTypes from 'prop-types';
import values from 'lodash/values';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import findIndex from 'lodash/findIndex';

import { getDisplayName } from '../../utils/hoc';

export const UPDATE_REGISTRATION_STATUS = gql`
    mutation updateRegistrationstatus($updates: [Update]!) {
        updateRegistrationstatus(updates: $updates) {
            newRegistrationstatus {
                id
                ceid
                status
                paid
                send_email
                confirmedAt
                company_blocking_period
                company_operation_times
                company_seasonal_blocking_period
            }
            errors {
                message
                extensions {
                    cause
                    message
                }
            }
        }
    }
`;

export default WrappedComponent => {
    class withRegistrationStatusUpdate extends Component {
        constructor(props) {
            super(props);
            this.state = {
                data: props.data,
                updates: {},
            };
        }

        changeLocalStatus = (key, value, rowIndex) => {
            const { updates, data } = this.state;
            const { ceid, coid } = data[rowIndex];
            setTimeout(() => {
                const currentValues = updates[rowIndex] ? updates[rowIndex].values : undefined;
                this.setState({
                    updates: {
                        ...updates,
                        [rowIndex]: {
                            ceid,
                            coid,
                            values: {
                                ...currentValues,
                                [key]: value,
                            },
                        },
                    },
                });
            }, 0);
        };

        handleUpdateRegistrationStatus = updateRegistrationstatus => {
            const { updates } = this.state;
            updateRegistrationstatus({ variables: { updates: values(updates) } });
        };

        onCompleted = data => {
            const { newRegistrationstatus } = data.updateRegistrationstatus;
            this.updateValues(newRegistrationstatus);
        };

        updateValues = updates => {
            const { data: stateData } = this.state;
            if (updates) {
                updates.forEach(update => {
                    const rowIndex = findIndex(stateData, ['id', update.id]);
                    if (stateData[rowIndex]) {
                        Object.keys(update).forEach(key => {
                            stateData[rowIndex][key] = update[key];
                        });
                    }
                });
                this.setState({
                    data: [...stateData],
                });
            }
        };

        render() {
            const { data, updates } = this.state;
            return (
                <Mutation mutation={UPDATE_REGISTRATION_STATUS} onCompleted={this.onCompleted}>
                    {(updateRegistrationstatus, { loading, error }) => (
                        <WrappedComponent
                            {...this.props}
                            data={data}
                            updates={updates}
                            changeLocalStatus={this.changeLocalStatus}
                            updateRegistrationstatus={() =>
                                this.handleUpdateRegistrationStatus(updateRegistrationstatus)
                            }
                            onReportChange={this.updateValues}
                            registrationstatusLoading={loading}
                            registrationstatusError={error}
                        />
                    )}
                </Mutation>
            );
        }
    }
    withRegistrationStatusUpdate.displayName = `withRegistrationStatusUpdate(${getDisplayName(
        WrappedComponent
    )})`;
    withRegistrationStatusUpdate.propTypes = {
        data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    };
    return withRegistrationStatusUpdate;
};
