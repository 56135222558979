import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'react-router/withRouter';
// import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';

import LanguageSwitcher from '../LanguageSwitcher';

import withLocale from '../../hocs/withLocale';
import { getAccessToken, logoutUser } from '../../utils/auth';
import { routes } from '../../index';

import IfsLogo from '../Icons/IfsLogo';
import config from '../../../config';

import styles from './styles.scss';

const Header = ({ history, trans, hasFixedLocale }, { client }) => (
    <div className={styles.header}>
        <Toolbar>
            <div className={styles.logo}>
                <a href={config.IFSPortalUrl}>
                    <IfsLogo />
                </a>
            </div>
            <div className={styles.grow} />
            {getAccessToken() ? (
                <Button
                    color="inherit"
                    onClick={() => {
                        logoutUser(client);
                        history.push(routes.login);
                    }}
                >
                    {trans('LOGIN_LOGOUT')}
                </Button>
            ) : (
                undefined
            )}
            {!hasFixedLocale ? <LanguageSwitcher /> : undefined}
        </Toolbar>
    </div>
);

Header.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
    trans: PropTypes.func.isRequired,
    hasFixedLocale: PropTypes.bool,
};

Header.defaultProps = {
    hasFixedLocale: false,
};

Header.contextTypes = {
    client: PropTypes.shape({
        resetStore: PropTypes.func,
    }).isRequired, // the apollo client injected by the apollo provider
};

export default withRouter(withLocale(Header));
