import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@devexpress/dx-react-grid-material-ui';

import StatusSelect from './StatusSelect';
import PaidCheckbox from './PaidCheckbox';
import EditInput from './EditInput';
import SendEmailCheckbox from './SendEmailCheckbox';

const TableCell = props => {
    const {
        column,
        tableRow: { rowId },
    } = props;
    const { updates, onChange, ...basicCellProps } = props;
    const update = updates[rowId] ? updates[rowId].values : undefined;
    if (column.name === 'status') {
        return (
            <Table.Cell {...basicCellProps}>
                <StatusSelect {...basicCellProps} update={update} onChange={onChange} />
            </Table.Cell>
        );
    }
    if (column.name === 'paid') {
        return (
            <Table.Cell {...basicCellProps}>
                <PaidCheckbox {...basicCellProps} update={update} onChange={onChange} />
            </Table.Cell>
        );
    }
    if (column.name === 'send_email') {
        return (
            <Table.Cell {...basicCellProps}>
                <SendEmailCheckbox {...basicCellProps} update={update} onChange={onChange} />
            </Table.Cell>
        );
    }

    if (
        column.name.match(
            /company_operation_times|company_blocking_period|company_seasonal_blocking_period/
        )
    ) {
        return (
            <Table.Cell {...basicCellProps}>
                <EditInput
                    valueKey={column.name}
                    {...basicCellProps}
                    update={update}
                    onChange={onChange}
                />
            </Table.Cell>
        );
    }
    return <Table.Cell {...basicCellProps} />;
};

TableCell.propTypes = {
    column: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    tableRow: PropTypes.shape({
        rowId: PropTypes.number.isRequired,
    }).isRequired,
    updates: PropTypes.objectOf(PropTypes.shape({ values: PropTypes.shape({}) })).isRequired,
};

export default TableCell;
