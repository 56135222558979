/**
 * COID Picker translations: es
 * Please keep this file sorted alphabetically
 */
export default {
    COID_INPUT_LABEL: 'COID',
    COID_INPUT_TEXT: 'Por favor inserte su COID',
    COID_SUBMIT: 'Enviar',
    COID_TITLE: 'Bienvenida :username',
};
