/**
 * Validation translations: en
 * Please keep this file sorted alphabetically
 */
export default {
    MANDATORY_FIELD: '* Mandatory field',
    VALIDATE_ACCEPT_AGBS: 'Please accept our General Terms and Conditions.',
    VALIDATE_ACCEPT_COSTS: 'Please confirm that you accept the costs.',
    VALIDATE_BILLING_ADDRESS_ALL_OR_NOTHING:
        'Different billing address must be filled out completely or left completely blank',
    VALIDATION_CERTIFICATES_HAVE_REGISTRATION:
        'There is already a registration for the current valid certificate of this COID.',
    VALIDATION_INVALID_EMAIL: 'Invalid email address',
    VALIDATION_MUST_CONTAIN_LETTER: ':field must contain a letter',
    VALIDATION_NO_CERTIFICATE: 'For this COID no valid certificates are available.',
    VALIDATION_NO_COMPANY_FOR_COID: ':field is no valid COID',
    VALIDATION_NO_EMAIL: 'Please type in a valid email address',
    VALIDATION_NO_NUMBER: ':field must be a Number',
    VALIDATION_NO_POSTALCODE: ':field must contain a valid postal code',
    VALIDATION_NOT_EMPTY: ':field must not be empty',
};
