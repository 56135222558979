import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LayoutGrid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableFilterRow,
    TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import {
    SortingState,
    IntegratedSorting,
    PagingState,
    IntegratedPaging,
    FilteringState,
    IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import SaveIcon from '@material-ui/icons/Save';

import TableCell from './components/TableCell';
import TableRow from './components/TableRow';
import TableHeaderCell from './components/TableHeaderCell';
import FilterCell from './components/FilterCell';
import ConfirmDialog from '../Common/ConfirmDialog';
import DateTypeProvider, { dateFilter } from './dataProviders/Date';
import ReportTypeProvider from './dataProviders/Report';
import CeidTypeProvider from './dataProviders/Ceid';

import { getColumns } from './tableConfig';
import withLocale from '../../hocs/withLocale';
import withRegistrationStatusUpdate from './withRegistrationStatusUpdate';
import withRegistrationStatus from './withRegistrationStatus';

import { getAccessToken } from '../../utils/auth';
import config from '../../../config';
import styles from './styles.scss';

const dateColumns = ['createdAt', 'confirmedAt'];
const dateFilters = dateColumns.map(column => dateFilter(column));
const defaultPageSizes = [10, 25, 50, 0];

class RegistrationStatus extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPage: 0,
            pageSize: props.defaultPageSize,
            showConfirmDialog: false,
        };
    }

    changeCurrentPage = currentPage => this.setState({ currentPage });

    changePageSize = pageSize => this.setState({ pageSize });

    handleDialogConfirm = () => {
        const { updateRegistrationstatus } = this.props;
        this.toggleDialog();
        updateRegistrationstatus();
    };

    toggleDialog = () => {
        const { showConfirmDialog } = this.state;
        this.setState({
            showConfirmDialog: !showConfirmDialog,
        });
    };

    render() {
        const { pageSize, currentPage, showConfirmDialog } = this.state;
        const {
            trans,
            registrationstatusLoading,
            data,
            changeLocalStatus,
            updates,
            onReportChange,
            pageSizes,
            defaultSorting,
        } = this.props;
        const columnConfig = getColumns(trans);
        const hasUnsavedChanges = Object.keys(updates).length > 0;

        return (
            <div className={styles.table}>
                <Grid rows={data} columns={columnConfig}>
                    <FilteringState defaultFilters={[]} columnExtensions={columnConfig} />
                    <IntegratedFiltering columnExtensions={dateFilters} />
                    <SortingState defaultSorting={defaultSorting} />
                    <PagingState
                        currentPage={currentPage}
                        onCurrentPageChange={this.changeCurrentPage}
                        pageSize={pageSize}
                        onPageSizeChange={this.changePageSize}
                    />
                    <IntegratedSorting />
                    <IntegratedPaging />
                    <DateTypeProvider for={dateColumns} />
                    <ReportTypeProvider for={['hasReport']} onChange={onReportChange} />
                    <CeidTypeProvider for={['ceid']} onChange={onReportChange} />
                    <Table
                        cellComponent={props => (
                            <TableCell {...props} updates={updates} onChange={changeLocalStatus} />
                        )}
                        rowComponent={props => <TableRow {...props} updates={updates} />}
                    />
                    <TableColumnResizing defaultColumnWidths={columnConfig} />
                    <TableHeaderRow showSortingControls cellComponent={TableHeaderCell} />
                    <PagingPanel pageSizes={pageSizes} />
                    <TableFilterRow cellComponent={FilterCell} />
                </Grid>
                <LayoutGrid container spacing={24} className={styles.buttons}>
                    <LayoutGrid item xs={12} sm={4}>
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={this.toggleDialog}
                            disabled={registrationstatusLoading || !hasUnsavedChanges}
                            data-test="registrationStatusSubmit"
                        >
                            {registrationstatusLoading ? (
                                <CircularProgress size={21} />
                            ) : (
                                trans('REGISTRATION_STATUS_SAVE_CHANGES')
                            )}
                        </Button>
                    </LayoutGrid>
                    <LayoutGrid item xs={12} sm={4}>
                        <Button
                            type="button"
                            fullWidth
                            color="primary"
                            size="large"
                            onClick={() => {
                                window.open(
                                    `${config.serverBase}/csv?token=${getAccessToken()}`,
                                    '_blank'
                                );
                            }}
                        >
                            <SaveIcon className={styles.saveIcon} />
                            {trans('REGISTRATION_STATUS_SAVE_CSV')}
                        </Button>
                    </LayoutGrid>
                </LayoutGrid>
                <ConfirmDialog
                    open={showConfirmDialog}
                    onConfirm={this.handleDialogConfirm}
                    onCancel={this.toggleDialog}
                    data-test="registrationStatusConfirmDialog"
                >
                    {trans('REGISTRATION_STATUS_CONFIRM_TEXT')}
                </ConfirmDialog>
            </div>
        );
    }
}

RegistrationStatus.propTypes = {
    changeLocalStatus: PropTypes.func.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    defaultPageSize: PropTypes.number,
    defaultSorting: PropTypes.arrayOf(
        PropTypes.shape({ columnName: PropTypes.string, direction: PropTypes.string })
    ),
    locale: PropTypes.string.isRequired,
    onReportChange: PropTypes.func.isRequired,
    pageSizes: PropTypes.arrayOf(PropTypes.number),
    registrationstatusError: PropTypes.shape({}),
    registrationstatusLoading: PropTypes.bool.isRequired,
    trans: PropTypes.func.isRequired,
    updates: PropTypes.shape({}).isRequired,
    updateRegistrationstatus: PropTypes.func.isRequired,
};
RegistrationStatus.defaultProps = {
    defaultPageSize: defaultPageSizes[0],
    defaultSorting: [{ columnName: 'createdAt', direction: 'desc' }],
    pageSizes: defaultPageSizes,
    registrationstatusError: undefined,
};

export default withRegistrationStatus(withRegistrationStatusUpdate(withLocale(RegistrationStatus)));
