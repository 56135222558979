import React from 'react';
import { IntegratedFiltering, DataTypeProvider } from '@devexpress/dx-react-grid';
import { formatDate } from '../../../utils/date';

export const dateFilter = columnName => ({
    columnName,
    predicate: (value, filter, row) => {
        if (!filter.value.length) return true;
        if (filter) {
            const dateString = formatDate(value);
            return dateString.indexOf(filter.value) > -1;
        }
        return IntegratedFiltering.defaultPredicate(value, filter, row);
    },
});

const DateFormatter = ({ value }) => formatDate(value);
const DateTypeProvider = props => (
    <DataTypeProvider formatterComponent={DateFormatter} {...props} />
);

export default DateTypeProvider;
