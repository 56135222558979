import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import styles from './styles.scss';

const RegisterCheckbox = ({
    onChange,
    value,
    identifier,
    autoFocus,
    label,
    disabled,
    error,
    required,
}) => (
    <FormControl
        margin="normal"
        required={required}
        fullWidth
        error={!!error}
        className={styles.checkbox}
    >
        <FormControlLabel
            label={label}
            className={styles.checkboxLabel}
            control={
                <Checkbox
                    id={identifier}
                    name={identifier}
                    value={identifier}
                    checked={value}
                    onChange={(event, checked) => onChange(event, checked, identifier)}
                    autoFocus={autoFocus}
                    disabled={disabled}
                    className={styles.checkbox}
                />
            }
        />
        {error ? (
            <FormHelperText id="component-error-text" className={styles.errorText}>
                {error()}
            </FormHelperText>
        ) : (
            undefined
        )}
    </FormControl>
);

RegisterCheckbox.propTypes = {
    autoFocus: PropTypes.bool,
    identifier: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.bool,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.func,
    required: PropTypes.bool,
};

RegisterCheckbox.defaultProps = {
    autoFocus: undefined,
    value: false,
    label: '',
    disabled: false,
    error: null,
    required: false,
};

export default RegisterCheckbox;
