import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import ReactMarkdown from 'react-markdown';
import { scroller } from 'react-scroll';

import Input from '../FormFields/Input';
import Select from '../FormFields/Select';
import HelpToolTip from '../FormFields/HelpToolTip';
import Checkbox from '../FormFields/Checkbox';
import ScrollTextCheck from '../FormFields/ScrollTextCheck';
import Loading from '../Loading';

import withLocale from '../../hocs/withLocale';
import languages from '../../const/formLanguage';
import { COUNTRIES } from '../../const/locale';

import styles from './styles.scss';
import validate from './validate';

export const REGISTER = gql`
    mutation register($coid: Int!, $formLanguage: FormLanguage!, $formData: FormData!) {
        register(coid: $coid, formLanguage: $formLanguage, formData: $formData) {
            status
        }
    }
`;

// we are forced to use underscores in variables because the API defines some of them
/* eslint-disable camelcase */
class RegisterForm extends Component {
    constructor(props) {
        super(props);
        this.state = RegisterForm.getStateFromProps(props);
    }

    static getDerivedStateFromProps(props, state) {
        if (state.loading && !props.loading) {
            return RegisterForm.getStateFromProps(props);
        }
        return null;
    }

    static getStateFromProps(props) {
        const {
            company: {
                company_name,
                legal_form,
                street,
                zip,
                city,
                vat,
                language,
                country,
                number_employees,
                contact: { contact_salutation, contact_name, contact_email, contact_phone },
                certificates,
            },
            loading,
        } = props;

        return {
            formData: {
                ceid: certificates[0] ? `${certificates[0].id}` : '',
                company_name,
                legal_form,
                street,
                zip,
                city,
                country,
                vat,
                language,
                number_employees,
                billing_company_name: '',
                billing_legal_form: '',
                billing_street: '',
                billing_zip: '',
                billing_city: '',
                billing_country: '',
                billing_vat: '',
                contact_salutation,
                contact_name,
                contact_email,
                contact_phone,
                contact_salutation_opt: '',
                contact_name_opt: '',
                contact_email_opt: '',
                contact_phone_opt: '',
                company_operation_times: '',
                company_blocking_period: '',
                company_seasonal_blocking_period: '',
            },
            loading,
            checkBoxes: {
                accept_AGBs: false,
                accept_costs: false,
            },
            errors: {},
        };
    }

    handleNumberChange = (event, value, key) => {
        this.handleChange(event, value.replace(/[^0-9]/g, ''), key);
    };

    handleChange = (event, value, key) => {
        const { formData } = this.state;
        this.setState({
            formData: {
                // set the whole formData so we are sure that the other values do not "change"
                // otherwise the InputLabel listening to state changes do not behave
                ...formData,
                [key]: value,
            },
        });
    };

    handleCheckboxChange = (event, value, key) => {
        const { checkBoxes } = this.state;
        this.setState({
            checkBoxes: {
                ...checkBoxes,
                [key]: value,
            },
        });
    };

    handleSubmit = (event, submitFunc) => {
        event.preventDefault();
        const { formData, checkBoxes } = this.state;
        const { trans, locale, coid } = this.props;
        const errors = validate({ ...formData, ...checkBoxes }, trans);

        if (Object.keys(errors).length) {
            this.setState({ errors }, () => {
                const firstError = Object.keys(errors)[0];
                // all inputs have an Element with identifier so react-scroll knows where to scroll to
                scroller.scrollTo(firstError, {
                    duration: 750,
                    delay: 100,
                    smooth: true,
                    offset: -50,
                });
            });
        } else {
            submitFunc({ variables: { coid, formLanguage: locale, formData } });
        }
    };

    render() {
        const {
            formData: {
                ceid,
                company_name,
                legal_form,
                street,
                zip,
                city,
                country,
                vat,
                language,
                number_employees,
                billing_company_name,
                billing_legal_form,
                billing_street,
                billing_zip,
                billing_city,
                billing_country,
                billing_vat,
                contact_salutation,
                contact_name,
                contact_email,
                contact_phone,
                contact_salutation_opt,
                contact_name_opt,
                contact_email_opt,
                contact_phone_opt,
                company_operation_times,
                company_blocking_period,
                company_seasonal_blocking_period,
            },
            checkBoxes: { accept_AGBs, accept_costs },
            errors,
        } = this.state;
        const { trans, onRegister, company, agb, coid, loading } = this.props;
        const { certificates } = company;

        if (loading) {
            return <Loading />;
        }
        return (
            <Mutation mutation={REGISTER} onCompleted={onRegister}>
                {(register, { loading: submitting }) => (
                    <form
                        className={styles.registerForm}
                        onSubmit={e => this.handleSubmit(e, register)}
                    >
                        <Typography variant="body1" className={styles.coidExplanation}>
                            {trans('REGISTER_COID_HINT')}
                        </Typography>
                        <Typography component="h2" variant="h5" className={styles.coidTitle}>
                            {trans('REGISTER_COID_TITLE')}
                        </Typography>
                        <Typography variant="subtitle1" className={styles.coidSubtitle}>
                            {coid}, {company.company_name}, {company.street}, {company.zip}{' '}
                            {company.city}
                        </Typography>
                        <Typography variant="body1" className={styles.coidExplanation}>
                            {trans('REGISTER_COID_TITLE_EXPLANATION')}
                        </Typography>
                        <Grid item xs={12}>
                            <Paper className={styles.paper}>
                                <Typography variant="h6">{trans('REGISTER_CEID_TITLE')}</Typography>
                                <Select
                                    value={ceid}
                                    options={certificates.map(cert => ({
                                        label: `${trans(cert.standard)} (${trans(
                                            'REGISTER_CEID_VALID_TO',
                                            {
                                                date: moment(cert.valid).format('MMM YYYY'),
                                            }
                                        )})`,
                                        value: `${cert.id}`,
                                    }))}
                                    identifier="ceid"
                                    label={trans('REGISTER_CEID')}
                                    onChange={this.handleChange}
                                    error={errors.ceid}
                                    required
                                />
                                <Typography variant="caption" className={styles.footNote}>
                                    {trans('MANDATORY_FIELD')}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Typography component="h2" variant="h5" className={styles.title}>
                            {trans('REGISTER_TITLE')}
                        </Typography>
                        <Grid container spacing={24}>
                            <Grid item xs={12} sm={6}>
                                <Paper className={styles.paper}>
                                    <Typography variant="h6">
                                        {trans('REGISTER_COMPANYADDRESS_TITLE')}
                                    </Typography>

                                    <Input
                                        value={company_name}
                                        identifier="company_name"
                                        label={trans('REGISTER_ADDRESS_COMPANYNAME')}
                                        onChange={this.handleChange}
                                        error={errors.company_name}
                                        required
                                    />

                                    <Input
                                        value={legal_form}
                                        identifier="legal_form"
                                        label={trans('REGISTER_ADDRESS_LEGALFORM')}
                                        onChange={this.handleChange}
                                        error={errors.legal_form}
                                        required
                                    />
                                    <Input
                                        value={street}
                                        identifier="street"
                                        label={trans('REGISTER_ADDRESS_STREET')}
                                        onChange={this.handleChange}
                                        error={errors.street}
                                        required
                                    />

                                    <Input
                                        value={zip}
                                        identifier="zip"
                                        label={trans('REGISTER_ADDRESS_ZIP')}
                                        onChange={this.handleChange}
                                        error={errors.zip}
                                        required
                                    />

                                    <Input
                                        value={city}
                                        identifier="city"
                                        label={trans('REGISTER_ADDRESS_CITY')}
                                        onChange={this.handleChange}
                                        error={errors.city}
                                        required
                                    />

                                    <Select
                                        value={country}
                                        options={COUNTRIES.map(c => ({
                                            label: c,
                                            value: c,
                                        }))}
                                        identifier="country"
                                        label={trans('REGISTER_ADDRESS_COUNTRY')}
                                        onChange={this.handleChange}
                                        error={errors.country}
                                        required
                                    />

                                    <Input
                                        value={vat}
                                        identifier="vat"
                                        label={trans('REGISTER_ADDRESS_VAT')}
                                        onChange={this.handleChange}
                                        error={errors.vat}
                                        required
                                    />

                                    <Input
                                        value={language}
                                        identifier="language"
                                        label={trans('REGISTER_COMPANYADDRESS_LANGUAGE')}
                                        onChange={this.handleChange}
                                        error={errors.language}
                                        required
                                    />
                                    <Typography variant="caption" className={styles.footNote}>
                                        {trans('MANDATORY_FIELD')}
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Paper className={styles.paper}>
                                    <Typography variant="h6">
                                        {trans('REGISTER_BILLINGADDRESS_TITLE')}
                                    </Typography>
                                    <Input
                                        value={billing_company_name}
                                        identifier="billing_company_name"
                                        label={trans('REGISTER_ADDRESS_COMPANYNAME')}
                                        onChange={this.handleChange}
                                        error={errors.billing_company_name}
                                    />
                                    <Input
                                        value={billing_legal_form}
                                        identifier="billing_legal_form"
                                        label={trans('REGISTER_ADDRESS_LEGALFORM')}
                                        onChange={this.handleChange}
                                        error={errors.billing_legal_form}
                                    />
                                    <Input
                                        value={billing_street}
                                        identifier="billing_street"
                                        label={trans('REGISTER_ADDRESS_STREET')}
                                        onChange={this.handleChange}
                                        error={errors.billing_street}
                                    />
                                    <Input
                                        value={billing_zip}
                                        identifier="billing_zip"
                                        label={trans('REGISTER_ADDRESS_ZIP')}
                                        onChange={this.handleChange}
                                        error={errors.billing_zip}
                                    />
                                    <Input
                                        value={billing_city}
                                        identifier="billing_city"
                                        label={trans('REGISTER_ADDRESS_CITY')}
                                        onChange={this.handleChange}
                                        error={errors.billing_city}
                                    />
                                    <Select
                                        value={billing_country}
                                        options={[
                                            { label: '', value: '' },
                                            ...COUNTRIES.map(c => ({
                                                label: c,
                                                value: c,
                                            })),
                                        ]}
                                        identifier="billing_country"
                                        label={trans('REGISTER_ADDRESS_COUNTRY')}
                                        onChange={this.handleChange}
                                        error={errors.billing_country}
                                    />
                                    <Input
                                        value={billing_vat}
                                        identifier="billing_vat"
                                        label={trans('REGISTER_ADDRESS_VAT')}
                                        onChange={this.handleChange}
                                        error={errors.billing_vat}
                                    />
                                </Paper>
                            </Grid>
                            <Typography component="h2" variant="h6" className={styles.describtion}>
                                {trans('REGISTER_CONTACT_DESCRIPTION')}
                            </Typography>

                            <Grid item xs={12} sm={6}>
                                <Paper className={styles.paper}>
                                    <Typography variant="h6">
                                        {`${trans('REGISTER_CONTACT_PERSON')}    `}
                                        <HelpToolTip
                                            title={trans('REGISTER_CONTACT_SEND_MAIL_TO')}
                                        />
                                    </Typography>
                                    <Select
                                        value={contact_salutation}
                                        options={[
                                            {
                                                label: trans('REGISTER_SALUTATION_FEMALE'),
                                                value: 'Ms.',
                                            },
                                            {
                                                label: trans('REGISTER_SALUTATION_MALE'),
                                                value: 'Mr.',
                                            },
                                        ]}
                                        identifier="contact_salutation"
                                        label={trans('REGISTER_CONTACT_SALUTATION')}
                                        onChange={this.handleChange}
                                        error={errors.contact_salutation}
                                        required
                                    />

                                    <Input
                                        value={contact_name}
                                        identifier="contact_name"
                                        label={trans('REGISTER_CONTACT_NAME')}
                                        onChange={this.handleChange}
                                        error={errors.contact_name}
                                        required
                                    />

                                    <Input
                                        value={contact_email}
                                        identifier="contact_email"
                                        label={trans('REGISTER_CONTACT_EMAIL')}
                                        onChange={this.handleChange}
                                        error={errors.contact_email}
                                        required
                                    />

                                    <Input
                                        value={contact_phone}
                                        identifier="contact_phone"
                                        label={trans('REGISTER_CONTACT_PHONE')}
                                        onChange={this.handleChange}
                                        error={errors.contact_phone}
                                        required
                                    />
                                    <Typography variant="caption" className={styles.footNote}>
                                        {trans('MANDATORY_FIELD')}
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Paper className={styles.paper}>
                                    <Typography variant="h6">
                                        {trans('REGISTER_CONTACT_PERSON_OPT')}
                                    </Typography>

                                    <Select
                                        value={contact_salutation_opt}
                                        options={[
                                            {
                                                label: '',
                                                value: '',
                                            },
                                            {
                                                label: trans('REGISTER_SALUTATION_FEMALE'),
                                                value: 'Ms.',
                                            },
                                            {
                                                label: trans('REGISTER_SALUTATION_MALE'),
                                                value: 'Mr.',
                                            },
                                        ]}
                                        identifier="contact_salutation_opt"
                                        label={trans('REGISTER_CONTACT_SALUTATION')}
                                        onChange={this.handleChange}
                                        error={errors.contact_salutation_opt}
                                    />

                                    <Input
                                        value={contact_name_opt}
                                        identifier="contact_name_opt"
                                        label={trans('REGISTER_CONTACT_NAME')}
                                        onChange={this.handleChange}
                                        error={errors.contact_name_opt}
                                    />

                                    <Input
                                        value={contact_email_opt}
                                        identifier="contact_email_opt"
                                        label={trans('REGISTER_CONTACT_EMAIL')}
                                        onChange={this.handleChange}
                                        error={errors.contact_email_opt}
                                    />

                                    <Input
                                        value={contact_phone_opt}
                                        identifier="contact_phone_opt"
                                        label={trans('REGISTER_CONTACT_PHONE')}
                                        onChange={this.handleChange}
                                        error={errors.contact_phone_opt}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={styles.paper}>
                                    <Typography variant="h6">
                                        {trans('REGISTER_FURTHER_DETAILS')}
                                    </Typography>

                                    <Input
                                        value={company_operation_times}
                                        identifier="company_operation_times"
                                        label={`${trans(
                                            'REGISTER_COMPANY_OPERATION_TIMES'
                                        )} ${trans('REGISTER_COMPANY_OPERATION_TIMES_DESC')}.`}
                                        onChange={this.handleChange}
                                        error={errors.company_operation_times}
                                        required
                                        tooltip={trans('REGISTER_COMPANY_OPERATION_TIMES_HELP')}
                                    />

                                    <Input
                                        value={company_blocking_period}
                                        identifier="company_blocking_period"
                                        label={trans('REGISTER_COMPANY_BLOCKING_PERIOD')}
                                        onChange={this.handleChange}
                                        error={errors.company_blocking_period}
                                        tooltip={trans('REGISTER_COMPANY_BLOCKING_PERIOD_HELP')}
                                    />

                                    <Input
                                        value={company_seasonal_blocking_period}
                                        identifier="company_seasonal_blocking_period"
                                        label={trans('REGISTER_COMPANY_SEASONAL_BLOCKING_PERIOD')}
                                        onChange={this.handleChange}
                                        error={errors.company_seasonal_blocking_period}
                                    />

                                    <Input
                                        value={number_employees}
                                        identifier="number_employees"
                                        label={trans('REGISTER_NUMBER_EMPLOYEES')}
                                        onChange={this.handleNumberChange}
                                        error={errors.number_employees}
                                        required
                                        type="tel"
                                    />

                                    <Typography variant="body1">
                                        <br />
                                        {trans('REGISTER_INFORM_IF_CHANGES_1')}{' '}
                                        <a
                                            href="mailto:safetychecks@ifs-certification.com"
                                            target="_top"
                                        >
                                            safetychecks@ifs-certification.com
                                        </a>
                                        {trans('REGISTER_INFORM_IF_CHANGES_2')}
                                    </Typography>
                                    <Typography variant="caption" className={styles.footNote}>
                                        {trans('MANDATORY_FIELD')}
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={styles.paper}>
                                    <Typography variant="h6">
                                        {trans('REGISTER_ORDER_IFSFOODCHECK')}
                                    </Typography>
                                    <ScrollTextCheck
                                        value={accept_AGBs}
                                        identifier="accept_AGBs"
                                        label={trans('REGISTER_ORDER_ACCEPT_GENERALTERMS')}
                                        onChange={this.handleCheckboxChange}
                                        error={errors.accept_AGBs}
                                    >
                                        <ReactMarkdown source={agb} />
                                    </ScrollTextCheck>

                                    <Checkbox
                                        identifier="accept_costs"
                                        value={accept_costs}
                                        onChange={this.handleCheckboxChange}
                                        label={trans('REGISTER_ORDER_ACCEPT_COSTS')}
                                        error={errors.accept_costs}
                                    />
                                    <Typography variant="body1">
                                        {trans('REGISTER_ORDER_INFORMATION_1')}
                                    </Typography>
                                    <Typography variant="body1">
                                        {trans('REGISTER_ORDER_INFORMATION_2')}
                                    </Typography>
                                    <Typography variant="body1">
                                        {trans('REGISTER_ORDER_INFORMATION_3')}
                                    </Typography>
                                    <Typography variant="caption" className={styles.footNote}>
                                        {trans('MANDATORY_FIELD')}
                                    </Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                        <Grid container spacing={24}>
                            <Grid item xs={12} sm={4}>
                                <Button
                                    disabled={submitting}
                                    className={styles.submitButton}
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={e => this.handleSubmit(e, register)}
                                >
                                    {submitting ? (
                                        <CircularProgress size={21} />
                                    ) : (
                                        trans('REGISTER_CONFIRM')
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Mutation>
        );
    }
}
RegisterForm.propTypes = {
    coid: PropTypes.number.isRequired,
    agb: PropTypes.string.isRequired,
    company: PropTypes.shape({
        company_name: PropTypes.string,
        legal_form: PropTypes.string,
        street: PropTypes.string,
        zip: PropTypes.string,
        city: PropTypes.string,
        vat: PropTypes.string,
        language: PropTypes.string,
        country: PropTypes.string,
        number_employees: PropTypes.string.isRequired,
        contact: PropTypes.shape({
            contact_salutation: PropTypes.string,
            contact_name: PropTypes.string,
            contact_email: PropTypes.string,
            contact_phone: PropTypes.string,
        }).isRequired,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
    locale: PropTypes.oneOf(languages).isRequired,
    onRegister: PropTypes.func.isRequired,
    trans: PropTypes.func.isRequired,
};

export default withLocale(RegisterForm);
