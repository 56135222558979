/**
 * Login translations: es
 * Please keep this file sorted alphabetically
 */
export default {
    LOGIN_LABEL_PASSWORD: 'Contraseña',
    LOGIN_LABEL_USERNAME: 'Dirección de email',
    LOGIN_LOGOUT: 'Cierre de sesión',
    LOGIN_SUBMIT: 'Registrarse',
    LOGIN_TEXT:
        'Estamos muy contentos de que haya decidido pedir un IFS Food Safety Check sin anunciar. Por favor, utilice sus datos de acceso de la base de datos IFS para continuar.',
    LOGIN_TITLE: 'Bienvenido al registro del Food Safety Check.',
    LOGIN_WRONG_PASSWORD: 'Nombre de usuario o contraseña incorrecta',
};
