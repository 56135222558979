import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'react-router/withRouter';
import { Query } from 'react-apollo';
import Typography from '@material-ui/core/Typography';
import { Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import { logoutUser } from '../../utils/auth';
import { routes } from '../../index';

import CoidPicker from '../../components/CoidPicker';

export const GET_USER = gql`
    {
        user {
            FirstName
            SurName
        }
    }
`;

const PickCoid = ({ history }) => (
    <div className="page">
        <Query query={GET_USER}>
            {({ data, loading, error, client }) => {
                if (loading) {
                    return null;
                }

                if (error) {
                    logoutUser(client);
                    return (
                        <Redirect
                            to={{
                                pathname: routes.login,
                                state: { from: { pathname: routes.pickCoid } },
                            }}
                        />
                    );
                }

                const { user } = data;
                return (
                    <Typography component="h1" variant="h5">
                        <CoidPicker
                            user={user}
                            onSubmit={coid => {
                                client.writeData({ data: { coid } });
                                history.push(routes.register);
                            }}
                        />
                    </Typography>
                );
            }}
        </Query>
    </div>
);

PickCoid.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
};

export default withRouter(PickCoid);
