import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Input from '@material-ui/core/Input';
import EditIcon from '@material-ui/icons/Edit';
import InputAdornment from '@material-ui/core/InputAdornment';

import styles from '../styles.scss';

class EditInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentValue: this.getValueFromProps(props),
        };
    }

    getValueFromProps = props => {
        const { value, update, valueKey } = props;
        return update && update[valueKey] ? update[valueKey] : value;
    };

    commitChanges = () => {
        const { tableRow, onChange, valueKey } = this.props;
        const { currentValue } = this.state;
        const valueFromProps = this.getValueFromProps(this.props);
        if (currentValue !== valueFromProps) {
            onChange(valueKey, currentValue, tableRow.rowId);
        }
    };

    handleChange = event => {
        this.setState({
            currentValue: event.target.value,
        });
    };

    render() {
        const { currentValue } = this.state;
        const {
            value,
            tableRow: { rowId },
            column: { name },
        } = this.props;
        const changed = currentValue !== value;
        return (
            <Input
                fullWidth
                value={currentValue}
                onChange={this.handleChange}
                onBlur={this.commitChanges}
                className={classnames(styles.editInput, { [styles.edited]: changed })}
                data-test={`editTextField-${name}-${rowId}`}
                endAdornment={
                    <InputAdornment position="end">
                        <EditIcon className={styles.icon} />
                    </InputAdornment>
                }
            />
        );
    }
}

EditInput.propTypes = {
    column: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    tableRow: PropTypes.shape({
        rowId: PropTypes.number,
    }).isRequired,
    update: PropTypes.shape({
        company_operation_times: PropTypes.string,
    }),
    // eslint-disable-next-line react/no-unused-prop-types
    value: PropTypes.string.isRequired,
    valueKey: PropTypes.string.isRequired,
};

EditInput.defaultProps = {
    update: undefined,
};

export default EditInput;
