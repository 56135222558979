import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

// hooks
import withLocale from '../../../../hocs/withLocale';
// components
import CertificateChangeDialog from './CertificateChangeDialog';
// styles
import styles from '../../styles.scss';

const Ceid = ({ ceid, coid, onChange, trans }) => {
    const [dialogVisible, setDialogVisible] = useState(false);
    return (
        <div className={styles.ceidFormatter}>
            <span data-test={`ceidValue-${ceid}`}>{ceid}</span>
            <Button
                size="small"
                onClick={() => setDialogVisible(true)}
                variant="contained"
                className={styles.button}
                data-test={`ceidChangeButton-${ceid}`}
            >
                {trans('CERTIFICATE_CHANGE_BUTTON')}
            </Button>

            <CertificateChangeDialog
                coid={coid}
                isDialogVisible={dialogVisible}
                currentCeid={ceid}
                onCancel={() => setDialogVisible(false)}
                onSubmit={data => onChange(data)}
            />
        </div>
    );
};

Ceid.propTypes = {
    ceid: PropTypes.number.isRequired,
    coid: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    trans: PropTypes.func.isRequired,
};

export default withLocale(Ceid);
