import React from 'react';
import PropTypes from 'prop-types';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

import CeidFormatter from '../dataFormatters/Ceid';

const CeidTypeProvider = props => {
    const { onChange, ...rest } = props;
    return (
        <DataTypeProvider
            formatterComponent={formatterProps => {
                const {
                    row: { ceid, coid },
                } = formatterProps;
                return <CeidFormatter ceid={ceid} coid={coid} onChange={onChange} />;
            }}
            {...rest}
        />
    );
};

CeidTypeProvider.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default CeidTypeProvider;
