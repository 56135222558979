import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import withLocale from '../../../hocs/withLocale';

import styles from './styles.scss';

const ConfirmDialog = ({
    open,
    onCancel,
    onConfirm,
    trans,
    children,
    confirmButtonText,
    cancelButtonText,
    loading,
}) => (
    <Dialog
        open={open}
        onClose={onCancel}
        className={styles.confirmDialog}
        disableBackdropClick={loading}
        disableEscapeKeyDown={loading}
    >
        <DialogContent>
            <DialogTitle>{children}</DialogTitle>
            <DialogActions>
                <Button
                    disabled={loading}
                    type="button"
                    onClick={onCancel}
                    color="default"
                    variant="contained"
                >
                    {cancelButtonText || trans('CANCEL')}
                </Button>
                <Button
                    disabled={loading || !onConfirm}
                    className={styles.submitButton}
                    type="button"
                    onClick={onConfirm}
                    color="primary"
                    variant="contained"
                    autoFocus
                    data-test="confirmDialogConfirmButton"
                >
                    {confirmButtonText || trans('SAVE')}
                </Button>
            </DialogActions>
        </DialogContent>
        <LinearProgress className={classnames(styles.progress, { [styles.visible]: loading })} />
    </Dialog>
);

ConfirmDialog.propTypes = {
    cancelButtonText: PropTypes.string,
    children: PropTypes.node.isRequired,
    confirmButtonText: PropTypes.string,
    loading: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func,
    open: PropTypes.bool,
    trans: PropTypes.func.isRequired,
};

ConfirmDialog.defaultProps = {
    confirmButtonText: undefined,
    cancelButtonText: undefined,
    loading: false,
    onConfirm: undefined,
    open: false,
};

export default withLocale(ConfirmDialog);
