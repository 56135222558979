import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Mutation, withApollo } from 'react-apollo';
import gql from 'graphql-tag';

import { setAccessToken } from '../../utils/auth';

import IfsLogo from '../Icons/IfsLogo';

import styles from './styles.scss';
import validate from './validate';
import withLocale from '../../hocs/withLocale';

export const LOGIN_USER = gql`
    mutation login($username: String!, $password: String!) {
        login(username: $username, password: $password) {
            status
            error_code
            data {
                access_token
                expires_at
            }
        }
    }
`;

export const GET_USER = gql`
    {
        user {
            IsAdmin
        }
    }
`;

class LoginForm extends Component {
    state = {
        email: '',
        password: '',
        errors: {},
    };

    handleChange = (event, key) => {
        this.setState({
            [key]: event.target.value,
        });
    };

    handleSubmit = loginFunc => {
        const { email, password } = this.state;
        const { trans } = this.props;
        const errors = validate({ email, password }, trans);
        if (Object.keys(errors).length) {
            this.setState({ errors });
        } else {
            loginFunc({ variables: { username: email, password } });
        }
    };

    loginComplete = loginRes => {
        const { onLogin, trans, client } = this.props;
        const { status, data } = loginRes.login;

        if (status) {
            const { access_token: token } = data;
            setAccessToken(token);
            client.query({ query: GET_USER }).then(({ data: { user } }) => {
                onLogin(user.IsAdmin);
            });
        } else {
            this.setState({
                errors: {
                    password: () => trans('LOGIN_WRONG_PASSWORD'),
                },
            });
        }
    };

    render() {
        const { email, password, errors } = this.state;
        const { trans } = this.props;
        return (
            <Mutation mutation={LOGIN_USER} onCompleted={this.loginComplete}>
                {(login, { loading }) => (
                    <Paper className={styles.loginForm}>
                        <div className={styles.logo}>
                            <IfsLogo />
                        </div>
                        <Typography component="h1" variant="h5" className={styles.title}>
                            {trans('LOGIN_TITLE')}
                        </Typography>
                        <Typography variant="body1" className={styles.textBody}>
                            {trans('LOGIN_TEXT')}
                        </Typography>
                        <form>
                            <FormControl margin="normal" required fullWidth error={!!errors.email}>
                                <InputLabel htmlFor="email">
                                    {trans('LOGIN_LABEL_USERNAME')}
                                </InputLabel>
                                <Input
                                    id="email"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    value={email}
                                    onChange={event => this.handleChange(event, 'email')}
                                />
                                {errors.email && (
                                    <FormHelperText id="component-error-text">
                                        {errors.email()}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl
                                margin="normal"
                                required
                                fullWidth
                                error={!!errors.password}
                            >
                                <InputLabel htmlFor="password" shrink>
                                    {trans('LOGIN_LABEL_PASSWORD')}
                                </InputLabel>
                                <Input
                                    name="password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    value={password}
                                    onChange={event => this.handleChange(event, 'password')}
                                />
                                {errors.password && (
                                    <FormHelperText id="component-error-text">
                                        {errors.password()}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <Button
                                disabled={loading}
                                className={styles.submitButton}
                                type="button"
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={() => this.handleSubmit(login)}
                            >
                                {loading ? <CircularProgress size={21} /> : trans('LOGIN_SUBMIT')}
                            </Button>
                        </form>
                    </Paper>
                )}
            </Mutation>
        );
    }
}

LoginForm.propTypes = {
    client: PropTypes.shape({
        query: PropTypes.func.isRequired,
    }).isRequired,
    onLogin: PropTypes.func.isRequired,
    trans: PropTypes.func.isRequired,
};

export default withApollo(withLocale(LoginForm));
