import React from 'react';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import withFormControl from './withFormControl';
import withToolTip from './withToolTip';

const RegisterInput = ({
    onChange,
    value,
    identifier,
    autoFocus,
    type,
    onBlur,
    onFocus,
    onMouseLeave,
    onMouseOver,
    onTouchEnd,
    onTouchStart,
}) => (
    <Input
        id={identifier}
        name={identifier}
        value={value}
        onChange={event => onChange(event, event.target.value, identifier)}
        autoFocus={autoFocus}
        type={type}
        // we pass the unused props along - Tooltip needs some of the hooks
        onBlur={onBlur}
        onFocus={onFocus}
        onMouseLeave={onMouseLeave}
        onMouseOver={onMouseOver}
        onTouchEnd={onTouchEnd}
        onTouchStart={onTouchStart}
    />
);
RegisterInput.propTypes = {
    autoFocus: PropTypes.bool,
    identifier: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    type: PropTypes.string,

    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onMouseOver: PropTypes.func,
    onTouchEnd: PropTypes.func,
    onTouchStart: PropTypes.func,
};

RegisterInput.defaultProps = {
    autoFocus: undefined,
    value: '',
    type: 'text',
    onBlur: undefined,
    onFocus: undefined,
    onMouseLeave: undefined,
    onMouseOver: undefined,
    onTouchEnd: undefined,
    onTouchStart: undefined,
};

export default withFormControl(withToolTip(RegisterInput));
