import isEmail from 'validator/lib/isEmail';
import isEmpty from 'validator/lib/isEmpty';

export default (values, trans) => {
    const errors = {};
    if (!values.email || isEmpty(values.email)) {
        errors.email = () =>
            trans('VALIDATION_NOT_EMPTY', { field: trans('LOGIN_LABEL_USERNAME') });
    } else if (!isEmail(values.email)) {
        errors.email = () => trans('VALIDATION_NO_EMAIL');
    }
    if (!values.password || isEmpty(values.password)) {
        errors.password = () =>
            trans('VALIDATION_NOT_EMPTY', { field: trans('LOGIN_LABEL_PASSWORD') });
    }
    return errors;
};
