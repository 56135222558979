import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

export default WrappedComponent => {
    const withToolTip = props => {
        const { tooltip } = props;

        if (tooltip) {
            return (
                <Tooltip title={<Typography color="inherit">{tooltip}</Typography>}>
                    <WrappedComponent {...props} />
                </Tooltip>
            );
        }
        return <WrappedComponent {...props} />;
    };

    withToolTip.propTypes = {
        tooltip: PropTypes.string,
    };

    withToolTip.defaultProps = {
        tooltip: undefined,
    };
    return withToolTip;
};
