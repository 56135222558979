import React from 'react';
import PropTypes from 'prop-types';
import LanguageIcon from '@material-ui/icons/Language';
import map from 'lodash/map';

import DropDown from '../DropDown';

import withLocale from '../../hocs/withLocale';
import languages from '../../const/formLanguage';
import styles from './styles.scss';

const LanguageSwitcher = ({ locale, trans, setLocale }) => (
    <DropDown
        label={
            <span className={styles.selectLabel}>
                <LanguageIcon className={styles.icon} />
                <span>{locale}</span>
            </span>
        }
        menuItems={map(languages, language => ({
            label: trans(`LANGUAGE_SWITCHER_${language}`),
            onClick: () => setLocale(language),
        }))}
        className={styles.languageSwitcher}
    />
);

LanguageSwitcher.propTypes = {
    locale: PropTypes.string.isRequired,
    trans: PropTypes.func.isRequired,
    setLocale: PropTypes.func.isRequired,
};

export default withLocale(LanguageSwitcher);
