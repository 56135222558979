import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import { Element } from 'react-scroll';

import Checkbox from './Checkbox';
import styles from './styles.scss';

class ScrollTextCheck extends Component {
    constructor(props) {
        super(props);

        this.state = {
            textRead: false,
        };
    }

    onScrollMount = node => {
        this.innerNode = node;

        if (node && node.scrollHeight <= node.clientHeight + node.scrollTop) {
            this.setState({ textRead: true });
        }
    };

    scrollHandler = () => {
        const { scrollHeight, clientHeight, scrollTop } = this.innerNode;
        if (scrollHeight === clientHeight + scrollTop) {
            this.setState({ textRead: true });
        }
    };

    render() {
        const { identifier, onChange, value, children, label, error } = this.props;
        const { textRead } = this.state;
        return (
            <div className={styles.scrollTextCheck}>
                <Element name={identifier} />
                <Paper className={styles.paper}>
                    <div
                        onScroll={this.scrollHandler}
                        ref={this.onScrollMount}
                        className={styles.scroll}
                    >
                        {children}
                    </div>
                </Paper>

                <Checkbox
                    identifier={identifier}
                    value={value}
                    onChange={onChange}
                    label={label}
                    disabled={!textRead}
                    error={error}
                />
            </div>
        );
    }
}

ScrollTextCheck.propTypes = {
    identifier: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.bool,
    label: PropTypes.string,
    children: PropTypes.node.isRequired,
    error: PropTypes.func,
};

ScrollTextCheck.defaultProps = {
    value: false,
    label: '',
    error: null,
};

export default ScrollTextCheck;
