import React from 'react';
import PropTypes from 'prop-types';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import ReportFormatter from '../dataFormatters/Report';

const ReportTypeProvider = props => {
    const { onChange, ...rest } = props;
    return (
        <DataTypeProvider
            formatterComponent={formatterProps => {
                const {
                    row: { ceid, hasReport },
                } = formatterProps;
                return <ReportFormatter ceid={ceid} hasReport={hasReport} onChange={onChange} />;
            }}
            {...rest}
        />
    );
};

ReportTypeProvider.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default ReportTypeProvider;
