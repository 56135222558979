import { formatDate } from '../../utils/date';

const columns = [
    { name: 'count', width: 120 }, // needs to be add in api
    { name: 'status', width: 270 },
    { name: 'paid', width: 100, filteringEnabled: false },
    { name: 'send_email', width: 100, filteringEnabled: false },
    { name: 'order_number', width: 180 }, // needs to be added in api
    {
        name: 'company_name',
        width: 220,
        getCellValue: row => `${row.company_name}, ${row.legal_form}`,
    },
    { name: 'coid', width: 100 },
    { name: 'ceid', width: 150 },
    { name: 'street', width: 220 },
    { name: 'zip', width: 100 },
    { name: 'city', width: 150 },
    { name: 'country', width: 150 },
    { name: 'vat', width: 100 },
    {
        name: 'billing_company_name',
        width: 220,
        getCellValue: row =>
            row.billing_company_name && row.billing_legal_form
                ? `${row.billing_company_name}, ${row.billing_legal_form}`
                : '',
    },
    { name: 'billing_street', width: 150 },
    { name: 'billing_zip', width: 100 },
    { name: 'billing_city', width: 100 },
    { name: 'billing_country', width: 150 },
    { name: 'billing_vat', width: 100 },
    { name: 'company_operation_times', width: 220 },
    { name: 'company_blocking_period', width: 220 },
    { name: 'company_seasonal_blocking_period', width: 220 },
    {
        name: 'certificate_next_audit',
        width: 220,
        getCellValue: row =>
            `${formatDate(row.certificate_next_audit_from)} - ${formatDate(
                row.certificate_next_audit_to
            )}`,
    },
    { name: 'certificate_valid', width: 150 },
    { name: 'number_employees', width: 100 },
    { name: 'language', width: 150 },
    { name: 'certificate_cb_responsible', width: 150 },
    { name: 'certificate_next_audit_date', width: 150 },
    { name: 'createdAt', width: 100 },
    { name: 'confirmedAt', width: 100 },
    { name: 'contact_salutation', width: 100 },
    { name: 'contact_name', width: 220 },
    { name: 'contact_email', width: 220 },
    { name: 'contact_phone', width: 150 },
    { name: 'contact_salutation_opt', width: 100 },
    { name: 'contact_name_opt', width: 150 },
    { name: 'contact_email_opt', width: 150 },
    { name: 'contact_phone_opt', width: 150 },
    { name: 'hasReport', width: 300, filteringEnabled: false },
];

export const getColumns = trans =>
    columns.map(column => ({
        name: column.name,
        columnName: column.name,
        title: trans ? trans(`COLUMN_${column.name}`) : column.name,
        width: column.width,
        filteringEnabled: column.filteringEnabled,
        getCellValue: column.getCellValue,
    }));
