import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { STATUS } from '../../../const/registrationStatus';
import withLocale from '../../../hocs/withLocale';

import styles from '../styles.scss';

export const statusList = [
    STATUS.Registered,
    STATUS.CertViolation,
    STATUS.Passed,
    STATUS.PassedQuestions,
    STATUS.Failed,
    STATUS.Cancelled,
    STATUS.Rejected,
];

class StatusSelect extends Component {
    handleValueChange = event => {
        const { tableRow, onChange } = this.props;
        onChange('status', event.target.value, tableRow.rowId);
    };

    render() {
        const { tableRow, value, update, trans } = this.props;
        const tempValue = update ? update.status : undefined;
        const statusChanged = tempValue && tempValue !== value;
        return (
            <div className={styles.status}>
                <span
                    className={classnames(styles.value, styles[`value-${value}`])}
                    data-test={`statusSelectValue-${tableRow.rowId}`}
                >
                    {trans(`STATUS_${value}`)}
                </span>
                <Select
                    value="none"
                    className={styles.select}
                    inputProps={{
                        name: `${tableRow.rowId}`,
                        id: `${tableRow.rowId}`,
                    }}
                    onChange={this.handleValueChange}
                    data-test={`statusSelect-${tableRow.rowId}`}
                >
                    <MenuItem value="none" disabled>
                        {statusChanged ? trans(`STATUS_${tempValue}`) : 'Change Status...'}
                    </MenuItem>
                    {statusList.map(status => (
                        <MenuItem key={status} value={status}>
                            {trans(`STATUS_${status}`)}
                        </MenuItem>
                    ))}
                </Select>
            </div>
        );
    }
}

StatusSelect.propTypes = {
    onChange: PropTypes.func,
    tableRow: PropTypes.shape({
        rowId: PropTypes.number,
    }).isRequired,
    trans: PropTypes.func.isRequired,
    update: PropTypes.shape({
        status: PropTypes.string,
    }),
    value: PropTypes.string,
};

StatusSelect.defaultProps = {
    onChange: () => {},
    update: undefined,
    value: undefined,
};

export default withLocale(StatusSelect);
