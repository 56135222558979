import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';

class PaidCheckbox extends Component {
    handleChange = event => {
        const { tableRow, onChange } = this.props;
        onChange('paid', event.target.checked, tableRow.rowId);
    };

    render() {
        const {
            value,
            update,
            tableRow: { rowId },
        } = this.props;
        const currentValue = update && update.paid !== undefined ? update.paid : value;
        return (
            <Checkbox
                checked={currentValue}
                value="paid"
                onChange={this.handleChange}
                color="default"
                data-test={`paidCheckbox-${rowId}`}
            />
        );
    }
}

PaidCheckbox.propTypes = {
    onChange: PropTypes.func.isRequired,
    tableRow: PropTypes.shape({
        rowId: PropTypes.number,
    }).isRequired,
    update: PropTypes.shape({
        paid: PropTypes.bool,
    }),
    value: PropTypes.bool.isRequired,
};

PaidCheckbox.defaultProps = {
    update: undefined,
};

export default PaidCheckbox;
