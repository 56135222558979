import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { routes } from '../../index';
import withLocale from '../../hocs/withLocale';
import { STATUS } from '../../const/registrationStatus';
import { getLinkToIfs } from '../../utils/ifs';
import config from '../../../config';

import styles from './styles.scss';

const greatingText = {
    [STATUS.Registered]: 'REGISTER_SUCCESS_TITLE',
    [STATUS.CertViolation]: 'REGISTER_SUCCESS_ON_HOLD_TITLE',
};

const infoTexts = {
    [STATUS.Registered]: 'REGISTER_SUCCESS_INVOICE_TEXT',
    [STATUS.CertViolation]: 'REGISTER_SUCCESS_ON_HOLD_TEXT',
};

const endingTexts = {
    [STATUS.Registered]: 'REGISTER_SUCCESS_ANOTHER_REGISTRATION_TEXT',
    [STATUS.CertViolation]: 'REGISTER_SUCCESS_GREETING',
};

const RegistrationSuccess = ({ coid, status, trans }) => (
    <div className={styles.registerSuccess}>
        <Typography component="h1" variant="h5">
            {trans(greatingText[status], { coid })}
        </Typography>
        <Typography variant="subtitle1">{trans(infoTexts[status])}</Typography>
        <Typography variant="h6" className={styles.anotherRegistration}>
            {trans(endingTexts[status])}
        </Typography>
        <Link to={routes.pickCoid}>
            <Button
                className={styles.submitButton}
                type="button"
                variant="contained"
                color="primary"
                size="large"
            >
                {trans('REGISTER_SUCCESS_BUTTON_REGISTER')}
            </Button>
        </Link>
        <a href={getLinkToIfs(config.IFSPortalUrl)}>
            <Button
                className={styles.submitButton}
                type="button"
                variant="contained"
                color="primary"
                size="large"
            >
                {trans('REGISTER_SUCCESS_BUTTON_BACK')}
            </Button>
        </a>
    </div>
);
RegistrationSuccess.propTypes = {
    coid: PropTypes.number.isRequired,
    status: PropTypes.oneOf([STATUS.Registered, STATUS.CertViolation]).isRequired,
    trans: PropTypes.func.isRequired,
};
export default withLocale(RegistrationSuccess);
