import React from 'react';
import PropTypes from 'prop-types';
import MaterialSelect from '@material-ui/core/Select';
import MenuItem from './MenuItem';
import withFormControl from './withFormControl';

import { isMobile } from '../../utils/dom';

const Select = ({ onChange, options, value, identifier, disabled }) => {
    const mobile = isMobile.any();
    const OptionField = mobile ? 'option' : MenuItem;
    return (
        <MaterialSelect
            native={mobile}
            value={value}
            onChange={event => onChange(event, event.target.value, identifier)}
            inputProps={{
                name: identifier,
                id: identifier,
            }}
            disabled={disabled}
        >
            {options.map(opt => (
                <OptionField key={opt.value} value={opt.value}>
                    {opt.label}
                </OptionField>
            ))}
        </MaterialSelect>
    );
};

Select.propTypes = {
    disabled: PropTypes.bool,
    identifier: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        })
    ).isRequired,
    value: PropTypes.string,
};

Select.defaultProps = {
    disabled: false,
    value: '',
};

export default withFormControl(Select);
