/**
 * COID Picker translations: de
 * Please keep this file sorted alphabetically
 */
export default {
    COID_INPUT_LABEL: 'COID',
    COID_INPUT_TEXT: 'Bitte geben Sie Ihre COID ein',
    COID_SUBMIT: 'Abschicken',
    COID_TITLE: 'Hallo :username',
};
