/**
 * Validation translations: es
 * Please keep this file sorted alphabetically
 */
export default {
    MANDATORY_FIELD: '* Campo obligatorio',
    VALIDATE_ACCEPT_AGBS: 'Por favor, acepte nuestros términos y condiciones generales.',
    VALIDATE_ACCEPT_COSTS: 'Por favor, confirme que acepta los costes.',
    VALIDATE_BILLING_ADDRESS_ALL_OR_NOTHING:
        'La dirección de facturación distinta debe estar completada o dejada completamente en blanco.',
    VALIDATION_CERTIFICATES_HAVE_REGISTRATION:
        'Ya existe un registro para el actual certificado válido de este COID.',
    VALIDATION_INVALID_EMAIL: 'Dirección de correo electrónico no es válida',
    VALIDATION_MUST_CONTAIN_LETTER: ':field debe contener una letra',
    VALIDATION_NO_CERTIFICATE: 'Para este COID no hay certificados disponibles.',
    VALIDATION_NO_COMPANY_FOR_COID: ':field erróneo, por favor introduzca un COID válido.',
    VALIDATION_NO_EMAIL: 'Por favor, introduzca una dirección de correo electrónico válida.',
    VALIDATION_NO_NUMBER: ':field debe ser un número',
    VALIDATION_NO_POSTALCODE: ':field debe contener un código postal válido',
    VALIDATION_NOT_EMPTY: ':field es un campo obligatorio',
};
