import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './styles.scss';

const Loading = () => (
    <div className={styles.loading}>
        <CircularProgress />
    </div>
);

export default Loading;
