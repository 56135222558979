/**
 * Registerform translations: de
 * Please keep this file sorted alphabetically
 */
export default {
    ifs_cashcarry2: 'IFS Wholesale 2',
    ifs_food6: 'IFS Food 6.1',
    ifs_food7: 'IFS Food 7',
    ifs_food8: 'IFS Food 8',
    REGISTER_ADDRESS_CITY: 'Stadt',
    REGISTER_ADDRESS_COMPANYNAME: 'Name',
    REGISTER_ADDRESS_COUNTRY: 'Land',
    REGISTER_ADDRESS_LEGALFORM: 'Rechtsform',
    REGISTER_ADDRESS_STREET: 'Straße, Nummer',
    REGISTER_ADDRESS_VAT: 'Umsatzsteuer-Identifikationsnummer (für EU Länder)',
    REGISTER_ADDRESS_ZIP: 'Postleitzahl',
    REGISTER_BILLINGADDRESS_COMPANY_NAME: 'Name',
    REGISTER_BILLINGADDRESS_LEGALFORM: 'Rechtsform',
    REGISTER_BILLINGADDRESS_TITLE: 'ggf., abweichende Rechnungsadresse',
    REGISTER_CEID: 'Standard',
    REGISTER_CEID_TITLE:
        'Bitte wählen Sie das entsprechende IFS Zertifikat zu dem der IFS Food Safety Check durchgeführt werden soll:',
    REGISTER_CEID_VALID_TO: 'gültig bis :date',
    REGISTER_COID_HINT:
        'Registrierung für den IFS Food Safety Check (es handelt sich hierbei NICHT um ein unangekündigtes IFS Zertifizierungsaudit)',
    REGISTER_COID_TITLE: 'Sie registrieren sich für folgende COID:',
    REGISTER_COID_TITLE_EXPLANATION:
        '(Beachten Sie, dass sich Ihre Registrierung auf das aktuell gültige Zertifikat bezieht, so dass der IFS Food Safety Check innerhalb dieser Zertifikatsgültigkeit durchgeführt wird!)',
    REGISTER_COMPANY_BLOCKING_PERIOD: 'Optionale produktionsfreie Zeiten und Sperrzeiten',
    REGISTER_COMPANY_BLOCKING_PERIOD_HELP:
        'Das Unternehmen hat die Möglichkeit bis zu 10 Betriebstage als Sperrzeit bekannt zu machen. Diese 10 Betriebstage können in maximal 3 Blöcke aufgeteilt werden und dienen der Berücksichtigung von unternehmensspezifischen Gründen wie geplante Kundenbesuche, Ferienzeiten, während derer ein IFS Food Safety Check nicht durchgeführt werden kann.',
    REGISTER_COMPANY_OPERATION_TIMES:
        'Übliche Betriebs- und Produktionszeiten des genannten Unternehmensstandorts',
    REGISTER_COMPANY_OPERATION_TIMES_DESC: '(z.B. Mo bis Mi 9:00 – 14:00, 16:00 - 22:00)',
    REGISTER_COMPANY_OPERATION_TIMES_HELP:
        'Diese Zeiten werden dem Auditor des IFS Food Safety Checks zur Verfügung gestellt, um zu gewährleisten, dass der IFS Food Safety Check zu für den Betrieb üblichen Betriebszeiten stattfindet.',
    REGISTER_COMPANY_SEASONAL_BLOCKING_PERIOD: 'Ggf., saisonale Produktionszeit',
    REGISTER_COMPANYADDRESS_LANGUAGE: 'Unternehmenssprache',
    REGISTER_COMPANYADDRESS_TITLE:
        'Name und Adresse des Unternehmens, zu dem der IFS Food Safety Check durchgeführt werden soll:',
    REGISTER_CONFIRM: 'Registrierung absenden',
    REGISTER_CONTACT_DESCRIPTION:
        'Kontaktperson in Ihrem Unternehmen, der dem Auditor beim IFS Food Safety Check zur Seite stehen wird:',
    REGISTER_CONTACT_EMAIL: 'Email',
    REGISTER_CONTACT_NAME: 'Vorname Nachname',
    REGISTER_CONTACT_PERSON: 'Ansprechpartner 1*',
    REGISTER_CONTACT_PERSON_OPT: 'Ansprechpartner 2',
    REGISTER_CONTACT_PHONE: 'Tel',
    REGISTER_CONTACT_SALUTATION: 'Anrede',
    REGISTER_CONTACT_SEND_MAIL_TO:
        'An diese Person wird die Anzahlungsrechnung zum IFS Food Safety Check via Email gesendet',
    REGISTER_FURTHER_DETAILS: 'Weitere Informationen',
    REGISTER_INFORM_IF_CHANGES_1:
        'Bitte informieren Sie uns zeitnah über kurzfristige Änderungen dieser Zeiten, an denen keine Produktion oder operative Tätigkeiten stattfinden (z.B. Tage, an denen andere Zertifizierungsverfahren in Ihrem Hause stattfinden; Produktionsstillstände aufgrund von Wartung/ Instandhaltung/ Schädlingsbekämpfungsmaßnahmen) per E-Mail: ',
    REGISTER_INFORM_IF_CHANGES_2: '.',
    REGISTER_NUMBER_EMPLOYEES: 'Mitarbeiteranzahl',
    REGISTER_ORDER_ACCEPT_COSTS:
        'Ja, ich möchte eine Bestellung für einen IFS Food Safety Check aufgeben und bin mir bewusst, dass ich für die anfallenden Kosten aufkommen muss. *',
    REGISTER_ORDER_ACCEPT_GENERALTERMS:
        'Ja, ich akzeptiere die allgemeinen Geschäftsbedingungen (auch verfügbar unter www.ifs-certification.com) *',
    REGISTER_ORDER_IFSFOODCHECK: 'Beauftragung eines IFS Food Safety Checks',
    REGISTER_ORDER_INFORMATION_1:
        'Die Rechnung erhalten Sie nach vollständiger Bearbeitung des Online-Auftragsformulars und dem damit verbundenen finalen Abschluss dieser Beauftragung. Die Rechnung muss im Voraus bezahlt werden.',
    REGISTER_ORDER_INFORMATION_2:
        'Bei diesem Auftrag handelt es sich um die Beauftragung eines einmaligen IFS Food Safety Checks, welcher sich auf die Laufzeit Ihres IFS Zertifikates bezieht.',
    REGISTER_ORDER_INFORMATION_3: ' ',
    REGISTER_SALUTATION_FEMALE: 'Frau',
    REGISTER_SALUTATION_MALE: 'Herr',
    REGISTER_SUCCESS_ANOTHER_REGISTRATION_TEXT:
        'Möchten Sie einen weiteren IFS Food Safety Check für eine andere COID bestellen?',
    REGISTER_SUCCESS_BUTTON_BACK: 'Zurück zu IFS home',
    REGISTER_SUCCESS_BUTTON_REGISTER: 'Bestellung',
    REGISTER_SUCCESS_GREETING: 'Mit freundlichen Grüßen, Ihr IFS Team',
    REGISTER_SUCCESS_INVOICE_TEXT:
        'Eine Anzahlungsrechnung wird Ihnen an die E-Mail der angegebenen Kontaktperson gesendet. Bitte begleichen Sie diese innerhalb von 14 Tagen.',
    REGISTER_SUCCESS_ON_HOLD_TEXT:
        'Allerdings wurden Unstimmigkeiten in Bezug auf die Rahmenbedingungen für die Beauftragung des IFS Food Safety Checks festgestellt. Wir prüfen Ihren Fall und werden Sie in Kürze kontaktieren.',
    REGISTER_SUCCESS_ON_HOLD_TITLE: 'Vielen Dank, Ihre Dateneingabe war erfolgreich.',
    REGISTER_SUCCESS_TITLE: 'Vielen Dank. Ihre Bestellung für die COID :coid war erfolgreich!',
    REGISTER_TITLE: 'Bitte geben Sie die folgenden Informationen an:',
};
