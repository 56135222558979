import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Query } from 'react-apollo';
import { Redirect, withRouter } from 'react-router-dom';

import gql from 'graphql-tag';
import find from 'lodash/find';
import filter from 'lodash/filter';

import withLocale from '../../hocs/withLocale';

import { logoutUser } from '../../utils/auth';
import { routes } from '../../index';

import RegisterForm from '../../components/RegisterForm';
import RegistrationSuccess from '../../components/RegistrationSuccess';

export const GET_COID = gql`
    query coid {
        coid @client
    }
`;
export const GET_COMPANY_WITH_AGB = gql`
    query companyAndAgb($coid: Int!, $lang: Language!) {
        agb(lang: $lang) {
            text
        }

        company(coid: $coid) {
            company_name
            legal_form
            street
            zip
            city
            vat
            language
            country
            number_employees
            contact {
                contact_salutation
                contact_name
                contact_email
                contact_phone
            }
            certificates {
                id
                standard
                valid
                hasRegistration
            }
        }
    }
`;

const NoCoidRedirect = () => (
    <Redirect
        to={{
            pathname: routes.pickCoid,
            state: { from: { pathname: routes.register } },
        }}
    />
);

const LoginRedirect = () => (
    <Redirect
        to={{
            pathname: routes.login,
            state: {
                from: {
                    pathname: routes.register,
                },
            },
        }}
    />
);

class Register extends Component {
    state = {
        status: undefined,
    };

    handleRegister = ({ register: { status } }) => {
        this.setState({ status });
    };

    renderCompanyQuery(coidState, coidStateloading) {
        const { locale } = this.props;
        if (coidStateloading) {
            return null;
        }

        if (!coidState || coidState.coid === null) {
            return NoCoidRedirect();
        }

        return (
            <Query
                query={GET_COMPANY_WITH_AGB}
                variables={{
                    lang: locale,
                    coid: coidState.coid,
                }}
                fetchPolicy="network-only"
            >
                {({ data, loading, error, client }) => {
                    // since we laod the query with fetchPolicy="no-cache" we sometimes have a render step where loading is not ture when reloading the query
                    // therefore we have to add the company = undefinded check here
                    if (loading && data.company === undefined) {
                        return null;
                    } // error for wrong coid

                    if (
                        error &&
                        find(error.graphQLErrors, gqlError => gqlError.message === '403')
                    ) {
                        return NoCoidRedirect();
                    } // error for invalid access token

                    if (
                        error &&
                        find(error.graphQLErrors, gqlError => gqlError.message === '400')
                    ) {
                        logoutUser(client);
                        return <LoginRedirect />;
                    }

                    const { company, agb } = data;
                    const certificatesWithoutRegistration = filter(
                        company.certificates,
                        cert => !cert.hasRegistration
                    );
                    if (certificatesWithoutRegistration.length === 0) {
                        return NoCoidRedirect();
                    }
                    return (
                        <RegisterForm
                            company={{
                                ...company,
                                certificates: certificatesWithoutRegistration,
                            }}
                            coid={coidState.coid}
                            onRegister={this.handleRegister}
                            agb={agb.text}
                            loading={loading}
                        />
                    );
                }}
            </Query>
        );
    }

    render() {
        const { status } = this.state;

        return (
            <Query query={GET_COID}>
                {({ data: coidState, loading: coidStateloading }) => {
                    if (status) {
                        return (
                            <div className="page">
                                <RegistrationSuccess status={status} coid={coidState.coid} />
                            </div>
                        );
                    }
                    return (
                        <div className="page">
                            {this.renderCompanyQuery(coidState, coidStateloading)}
                        </div>
                    );
                }}
            </Query>
        );
    }
}

Register.propTypes = {
    locale: PropTypes.string.isRequired,
};

export default withLocale(withRouter(Register));
