/**
 * Login translations: de
 * Please keep this file sorted alphabetically
 */
export default {
    LOGIN_LABEL_PASSWORD: 'Passwort',
    LOGIN_LABEL_USERNAME: 'E-mail Adresse',
    LOGIN_LOGOUT: 'Abmelden',
    LOGIN_SUBMIT: 'Anmelden',
    LOGIN_TEXT:
        'Wir begrüßen es sehr, dass Sie sich zum unangekündigten IFS Food Safety Check anmelden möchten. Bitte geben Sie Ihre Login Daten der IFS Datenbank ein.',
    LOGIN_TITLE: 'Willkommen zur Food Safety Check Registrierung',
    LOGIN_WRONG_PASSWORD: 'E-mail und Password stimmen nicht überein',
};
