/**
 * Login translations: en
 * Please keep this file sorted alphabetically
 */
export default {
    LOGIN_LABEL_PASSWORD: 'Password',
    LOGIN_LABEL_USERNAME: 'Email Address',
    LOGIN_LOGOUT: 'Logout',
    LOGIN_SUBMIT: 'Sign in',
    LOGIN_TEXT:
        'We are very pleased that you have decided to order an unannounced IFS Food Safety Check. Please use your LOGIN Data of the IFS database to continue.',
    LOGIN_TITLE: 'Welcome to the Food Safety Check registration',
    LOGIN_WRONG_PASSWORD: 'Wrong username or password',
};
