import React from 'react';
import { Redirect } from 'react-router-dom';
import withRouter from 'react-router/withRouter';

import { logoutUser } from '../../utils/auth';
import { routes } from '../../index';

import RegistrationStatus from '../../components/RegistrationStatus';

const Admin = () => (
    <div className="adminPage">
        <RegistrationStatus
            onError={client => {
                logoutUser(client);
                return (
                    <Redirect
                        to={{
                            pathname: routes.login,
                            state: { from: { pathname: routes.admin } },
                        }}
                    />
                );
            }}
        />
    </div>
);

export default withRouter(Admin);
