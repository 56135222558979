/**
 * Registerform translations: es
 * Please keep this file sorted alphabetically
 */
export default {
    ifs_cashcarry2: 'Mayorista 2',
    ifs_food6: 'IFS Food 6.1',
    ifs_food7: 'IFS Food 7',
    ifs_food8: 'IFS Food 8',
    REGISTER_ADDRESS_CITY: 'Ciudad',
    REGISTER_ADDRESS_COMPANYNAME: 'Nombre',
    REGISTER_ADDRESS_COUNTRY: 'País',
    REGISTER_ADDRESS_LEGALFORM: 'Estado legal',
    REGISTER_ADDRESS_STREET: 'Calle, Nº',
    REGISTER_ADDRESS_VAT: 'VATIN (para países Europeos)',
    REGISTER_ADDRESS_ZIP: 'Código postal',
    REGISTER_BILLINGADDRESS_COMPANY_NAME: 'Nombre',
    REGISTER_BILLINGADDRESS_LEGALFORM: 'Estado legal',
    REGISTER_BILLINGADDRESS_TITLE: 'Si aplica, diferentes direcciones de facturación',
    REGISTER_CEID: 'Standard',
    REGISTER_CEID_TITLE:
        'Por favor, elija el certificado IFS para el cual se realizará el IFS Food Safety Check:',
    REGISTER_CEID_VALID_TO: 'válido hasta :date',
    REGISTER_COID_HINT:
        'Registro en IFS Food Safety Check (NO vinculado a una auditoría de certificación IFS no anunciada).',
    REGISTER_COID_TITLE: 'Se registrará con el siguiente COID',
    REGISTER_COID_TITLE_EXPLANATION:
        '(Tenga en cuenta que su registro se refiere al certificado actualmente válido, por lo que el IFS Food Safety Check se realizará dentro del periodo de validez de este certificado!)',
    REGISTER_COMPANY_BLOCKING_PERIOD: 'Opcional: periodos no productivos y periodos de bloqueo',
    REGISTER_COMPANY_BLOCKING_PERIOD_HELP:
        'La compañía puede bloquear 10 días dentro de los periodos habituales de trabajo dentro de la compañía. Estos 10 días operacionales pueden ser divididos en un máximo de 3 bloques y será debidamente justificado, ya sea porque hay visitas planeadas de clientes, o vacaciones etc. Se entiende que en estos días no se puede realizar el IFS Food Safety Check.',
    REGISTER_COMPANY_OPERATION_TIMES:
        'Periodos habituales de trabajo y producción del establecimiento auditado',
    REGISTER_COMPANY_OPERATION_TIMES_DESC: '(p.ej. L-X de 9:00 - 14:00, 16:00 - 22:00)',
    REGISTER_COMPANY_OPERATION_TIMES_HELP:
        'IFS enviará estos datos al auditor responsable del IFS Food Safety Check para garantizar que el Check se realizará en los horarios proporcionados.',
    REGISTER_COMPANY_SEASONAL_BLOCKING_PERIOD: 'If applicable, seasonal production period',
    REGISTER_COMPANYADDRESS_LANGUAGE: 'Idioma de la compañía',
    REGISTER_COMPANYADDRESS_TITLE:
        'Nombre y dirección de la compañía para el cual se realizará el IFS Food Safety Check:',
    REGISTER_CONFIRM: 'Enviar registro',
    REGISTER_CONTACT_DESCRIPTION:
        'Persona de contacto que acompañará al auditor durante el IFS Food Safety Check:',
    REGISTER_CONTACT_EMAIL: 'E-mail',
    REGISTER_CONTACT_NAME: 'Nombre Apellidos',
    REGISTER_CONTACT_PERSON: 'Persona 1*',
    REGISTER_CONTACT_PERSON_OPT: 'Persona 2',
    REGISTER_CONTACT_PHONE: 'Teléfono',
    REGISTER_CONTACT_SALUTATION: 'Tratamiento',
    REGISTER_CONTACT_SEND_MAIL_TO:
        'Por favor tenga en cuenta que la factura será enviada al email de la persona 1',
    REGISTER_FURTHER_DETAILS: 'Información adicional',
    REGISTER_INFORM_IF_CHANGES_1:
        'Por favor manténganos informados si ocurren cambios a corto plazo en los periodos cuando no hay producción o periodos de producción (p.ej. días que coincidan con otras auditorías de certificación, o parones debido a tareas de reparación/mantenimiento/ control de plagas), vía email a la siguiente dirección: ',
    REGISTER_INFORM_IF_CHANGES_2: '.',
    REGISTER_NUMBER_EMPLOYEES: 'número de empleados',
    REGISTER_ORDER_ACCEPT_COSTS:
        'Sí, me gustaría ordenar un pedido para un IFS Food Safety Check y que puedo hacerme cargo de los gastos que se generen con el mismo. *',
    REGISTER_ORDER_ACCEPT_GENERALTERMS:
        'Sí, acepto los Términos Generales y Condiciones (disponibles en www.ifs-certification.com) *',
    REGISTER_ORDER_IFSFOODCHECK: 'Pedido de un IFS Food Safety Check',
    REGISTER_ORDER_INFORMATION_1:
        'Usted recibirá la factura después de completar on line este formulario y la respectiva conclusión final.',
    REGISTER_ORDER_INFORMATION_2:
        'Esta factura tiene que pagarse por adelantado y solo después, el registro al IFS Food Safety Check será visible para todos los usuarios en la base de datos de IFS.',
    REGISTER_ORDER_INFORMATION_3:
        'El IFS Food Safety Check se asigna sólo a un período de validez del certificado y la órden es válida para un solo IFS Food Safety Check.',
    REGISTER_SALUTATION_FEMALE: 'Ls Sra.',
    REGISTER_SALUTATION_MALE: 'El Sr.',
    REGISTER_SUCCESS_ANOTHER_REGISTRATION_TEXT:
        '¿Desea ordenar un Food Safety Check para otro COID?',
    REGISTER_SUCCESS_BUTTON_BACK: 'Regreso a IFS Home',
    REGISTER_SUCCESS_BUTTON_REGISTER: 'Pedido',
    REGISTER_SUCCESS_GREETING: 'Saludos, el equipo IFS',
    REGISTER_SUCCESS_INVOICE_TEXT:
        'Se enviará una factura por adelantado a la dirección de correo electrónico de la persona de contacto mencionada. La factura debe pagarse dentro del periodo de 14 días laborables.',
    REGISTER_SUCCESS_ON_HOLD_TEXT:
        'Sin embargo, hemos encontrado fallos dentro del marco de las condiciones de aceptación de los IFS Food Safey Checks. Investigaremos su caso y le contactaremos pronto.',
    REGISTER_SUCCESS_ON_HOLD_TITLE: 'Gracias, sus datos se han registrado correctamente.',
    REGISTER_SUCCESS_TITLE: 'Gracias, su pedido para el COID :coid se ha realizado con éxito!',
    REGISTER_TITLE: 'Por favor, introduzca la siguiente información',
};
