export const getAccessToken = () => localStorage.ifsToken;

export const setAccessToken = token => {
    localStorage.ifsToken = token;
};

export const deleteAccessToken = () => delete localStorage.ifsToken;

/**
 *
 * @param {object} client    this is the apoloo client - get it from the context provided by the Apollo provider
 * the contextType should be like this (ull not find this in the offical apollo docu):
 * MyReactComponent.contextTypes = {
 *  client: PropTypes.shape({
 *      resetStore: PropTypes.func,
 *  }).isRequired, // the apollo client injected by the apollo provider
 * };
 */
export const logoutUser = client => {
    deleteAccessToken();
    // we have to make sure we cleared all cached gql-queries
    // so we dont give a chaced response from an old user to the current user
    client.resetStore();
};
