/**
 * Login translations: fr
 * Please keep this file sorted alphabetically
 */
export default {
    LOGIN_LABEL_PASSWORD: 'Mot de passe',
    LOGIN_LABEL_USERNAME: 'Adresse e-mail',
    LOGIN_LOGOUT: 'Déconnexion',
    LOGIN_SUBMIT: 'S‘inscrire',
    LOGIN_TEXT:
        'Nous sommes très heureux que vous ayez décidé(e) de commander un IFS Food Safety Check  inopiné. Pour continuer, veuillez faire usage de vos données de connexion de la base de données IFS.',
    LOGIN_TITLE: 'Bienvenue sur le site - d’inscription au Food Safety Check.',
    LOGIN_WRONG_PASSWORD: 'Nom d’utilisateur ou mot de passe incorrect',
};
