import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import { base64StringToBlob } from 'blob-util';
import { saveAs } from 'file-saver';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Mutation, withApollo } from 'react-apollo';
import gql from 'graphql-tag';

import ConfirmDialog from '../../Common/ConfirmDialog';
import styles from '../styles.scss';
import withLocale from '../../../hocs/withLocale';
import { fileToBase64 } from '../../../utils/file';

export const UPLOAD_REPORT = gql`
    mutation($ceid: String!, $file: String!) {
        uploadReport(ceid: $ceid, file: $file) {
            id
            ceid
            hasReport
        }
    }
`;

export const DELETE_REPORT = gql`
    mutation($ceid: String!) {
        deleteReport(ceid: $ceid) {
            id
            ceid
            hasReport
        }
    }
`;

export const GET_REPORT = gql`
    query report($ceid: String!) {
        report(ceid: $ceid) {
            file_name
            encoded_data
        }
    }
`;

class ReportFormatter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDownloading: false,
        };
        this.fileInput = createRef();
    }

    state = {
        base64: undefined,
        filename: undefined,
        showDeleteDialog: false,
    };

    handlePdfLinkClick = () => {
        const { client, ceid } = this.props;
        this.setState(
            {
                isDownloading: true,
            },
            () =>
                client
                    .query({
                        query: GET_REPORT,
                        fetchPolicy: 'no-cache',
                        variables: { ceid: `${ceid}` },
                    })
                    .then(({ data: { report } }) => {
                        const blob = base64StringToBlob(report.encoded_data, 'application/pdf');
                        saveAs(blob, report.file_name);
                        this.setState({ isDownloading: false });
                    })
                    .catch(() => this.setState({ isDownloading: false }))
        );
    };

    handleFileSelect = async event => {
        const {
            target: {
                validity,
                files: [file],
            },
        } = event;
        if (validity.valid) {
            const base64 = await fileToBase64(file);
            this.setState({ base64, filename: file.name });
            if (this.fileInput.current) {
                this.fileInput.current.value = '';
            }
        }
    };

    handleUploadDialogCancel = () => {
        this.setState({ base64: undefined, filename: undefined });
    };

    handleUploadDialogConfirm = uploadReport => {
        const { ceid } = this.props;
        const { base64 } = this.state;
        uploadReport({
            variables: {
                ceid: `${ceid}`,
                file: base64,
            },
        });
    };

    handleUploadCompleted = ({ uploadReport }) => {
        const { onChange } = this.props;
        onChange(uploadReport);
    };

    handleDeleteClick = () => {
        this.setState({ showDeleteDialog: true });
    };

    handleDeleteCancel = () => {
        this.setState({ showDeleteDialog: false });
    };

    handleDeleteCompleted = ({ deleteReport }) => {
        const { onChange } = this.props;
        onChange(deleteReport);
    };

    render() {
        const { filename, showDeleteDialog, isDownloading } = this.state;
        const { ceid, trans, hasReport } = this.props;

        return (
            <div className={styles.reportFormatter}>
                {hasReport ? (
                    <Button
                        size="small"
                        onClick={this.handlePdfLinkClick}
                        variant="contained"
                        color="primary"
                        className={styles.button}
                        data-test={`reportDownloadButton-${ceid}`}
                        disabled={isDownloading}
                    >
                        PDF
                        {isDownloading ? (
                            <CircularProgress size={15} className={styles.downloadProgress} />
                        ) : (
                            <SaveAlt fontSize="small" className={styles.buttonIcon} />
                        )}
                    </Button>
                ) : (
                    undefined
                )}
                <Mutation mutation={UPLOAD_REPORT} onCompleted={this.handleUploadCompleted}>
                    {(uploadReport, { loading }) => (
                        <label htmlFor={`reportUpload-${ceid}`}>
                            <Button
                                size="small"
                                variant="contained"
                                color="secondary"
                                component="span"
                                className={styles.button}
                            >
                                {trans('REGISTRATION_STATUS_FILE_UPLOAD_BUTTON')}
                                <CloudUploadIcon fontSize="small" className={styles.buttonIcon} />
                                <input
                                    accept="application/pdf"
                                    id={`reportUpload-${ceid}`}
                                    type="file"
                                    data-test={`uploadReportInput-${ceid}`}
                                    onChange={event => this.handleFileSelect(event)}
                                    ref={this.fileInput}
                                />
                            </Button>
                            <ConfirmDialog
                                open={!!filename}
                                onConfirm={() => this.handleUploadDialogConfirm(uploadReport)}
                                onCancel={this.handleUploadDialogCancel}
                                confirmButtonText={trans('REGISTRATION_STATUS_FILE_UPLOAD_BUTTON')}
                                loading={loading}
                                data-test={`uploadConfirmDialog-${ceid}`}
                            >
                                {trans('REGISTRATION_STATUS_FILE_UPLOAD_CONFIRM', {
                                    filename,
                                })}
                            </ConfirmDialog>
                        </label>
                    )}
                </Mutation>
                <Mutation mutation={DELETE_REPORT} onCompleted={this.handleDeleteCompleted}>
                    {(deleteReport, { loading }) => (
                        <Fragment>
                            <Button
                                onClick={this.handleDeleteClick}
                                variant="contained"
                                color="default"
                                size="small"
                                data-test={`deleteReportButton-${ceid}`}
                                disabled={!hasReport}
                            >
                                Delete
                                <DeleteIcon fontSize="small" className={styles.buttonIcon} />
                            </Button>
                            <ConfirmDialog
                                open={showDeleteDialog}
                                onConfirm={() => deleteReport({ variables: { ceid: `${ceid}` } })}
                                onCancel={this.handleDeleteCancel}
                                confirmButtonText={trans('REGISTRATION_STATUS_FILE_DELETE_BUTTON')}
                                loading={loading}
                                data-test={`deleteConfirmDialog-${ceid}`}
                            >
                                {trans('REGISTRATION_STATUS_FILE_DELETE_CONFIRM')}
                            </ConfirmDialog>
                        </Fragment>
                    )}
                </Mutation>
            </div>
        );
    }
}

ReportFormatter.propTypes = {
    ceid: PropTypes.number.isRequired,
    client: PropTypes.shape({
        query: PropTypes.func.isRequired,
    }).isRequired,
    hasReport: PropTypes.bool.isRequired,
    trans: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default withApollo(withLocale(ReportFormatter));
