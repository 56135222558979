import isDecimal from 'validator/lib/isDecimal';
import isEmpty from 'validator/lib/isEmpty';

export const validate = (values, trans) => {
    if (!values.coid || isEmpty(values.coid)) {
        return () => trans('VALIDATION_NOT_EMPTY', { field: trans('COID_INPUT_LABEL') });
    }

    if (!isDecimal(values.coid)) {
        return () => trans('VALIDATION_NO_NUMBER', { field: trans('COID_INPUT_LABEL') });
    }

    return null;
};

export const getNoCertificateError = (coid, trans) => () =>
    trans('VALIDATION_NO_CERTIFICATE', { field: coid });

export const getNoCertificateWithoutRegistrationError = (coid, trans) => () =>
    trans('VALIDATION_CERTIFICATES_HAVE_REGISTRATION', { field: coid });

export const getNoCompanyForCoidError = (coid, trans) => () =>
    trans('VALIDATION_NO_COMPANY_FOR_COID', { field: coid });
