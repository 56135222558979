import Login from './login';
import CoidPicker from './coidPicker';
import RegisterForm from './registerForm';
import Validation from './validation';
import RegistrationStatus from './registrationStatus';
import Common from './common';
import LanguageSwitcher from '../languageSwitcher';

export default {
    ...Common,
    ...Login,
    ...CoidPicker,
    ...RegisterForm,
    ...Validation,
    ...RegistrationStatus,
    ...LanguageSwitcher,
};
