import React from 'react';
import { hot } from 'react-hot-loader';
import { Route, Switch, Link } from 'react-router-dom';

import PrivateRoute from './routing/PrivateRoute';
import QueryCapturer from './routing/QueryCapturer';
import Login from './containers/Login';
import PickCoid from './containers/PickCoid';
import Register from './containers/Register';
import Admin from './containers/Admin/index';
import MainLayout from './layout/Main';
import LocaleProvider from './context/LocaleProvider';

import styles from './styles.scss';

export const routes = {
    login: '/login',
    pickCoid: '/coid',
    register: '/',
    admin: '/admin',
};

const NoMatch = () => (
    <div>
        Ooops <Link to={routes.pickCoid}>go back to home</Link>
    </div>
);

const UserArea = () => (
    <LocaleProvider>
        <MainLayout>
            <Switch>
                <PrivateRoute exact path={routes.pickCoid} component={PickCoid} />
                <PrivateRoute exact path={routes.register} component={Register} />
                <Route exact path={routes.login} component={Login} />
                <Route component={NoMatch} />
            </Switch>
        </MainLayout>
    </LocaleProvider>
);

const AdminArea = () => (
    <LocaleProvider fixedLocale="en">
        <MainLayout>
            <PrivateRoute exact path={routes.admin} component={Admin} userRole="admin" />
        </MainLayout>
    </LocaleProvider>
);

const App = () => (
    <div className={styles.app}>
        <Route component={QueryCapturer} />
        <Switch>
            <Route exact path={routes.admin} component={AdminArea} />
            <Route path="*" component={UserArea} />
        </Switch>
    </div>
);

export default hot(module)(App);
