import React from 'react';

import { LocaleContext } from '../context/LocaleProvider';

import { trans } from '../utils/locale';
import { getDisplayName } from '../utils/hoc';

export default WrappedComponent => {
    const WithLocale = props => (
        <LocaleContext.Consumer>
            {data => (
                <WrappedComponent
                    {...props}
                    locale={data.locale}
                    hasFixedLocale={data.hasFixedLocale}
                    setLocale={data.changeLocale}
                    trans={trans}
                />
            )}
        </LocaleContext.Consumer>
    );
    WithLocale.displayName = `WithLocale(${getDisplayName(WrappedComponent)})`;
    return WithLocale;
};
