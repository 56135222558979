/**
 * Registerform translations: en
 * Please keep this file sorted alphabetically
 */
export default {
    ifs_cashcarry2: 'IFS Wholesale 2',
    ifs_food6: 'IFS Food 6.1',
    ifs_food7: 'IFS Food 7',
    ifs_food8: 'IFS Food 8',
    REGISTER_ADDRESS_CITY: 'City',
    REGISTER_ADDRESS_COMPANYNAME: 'Name',
    REGISTER_ADDRESS_COUNTRY: 'Country',
    REGISTER_ADDRESS_LEGALFORM: 'Legal Form',
    REGISTER_ADDRESS_STREET: 'Street, No',
    REGISTER_ADDRESS_VAT: 'VATIN (for EU countries)',
    REGISTER_ADDRESS_ZIP: 'Zip, code',
    REGISTER_BILLINGADDRESS_COMPANY_NAME: 'Name',
    REGISTER_BILLINGADDRESS_LEGALFORM: 'Legal Form',
    REGISTER_BILLINGADDRESS_TITLE: 'If applicable, different billing address',
    REGISTER_CEID: 'Standard',
    REGISTER_CEID_TITLE:
        'Please choose the relevant IFS certificate for which the IFS Food Safety Check shall be carried out:',
    REGISTER_CEID_VALID_TO: 'valid until :date',
    REGISTER_COID_HINT:
        'Registration to IFS Food Safety Check (NOT linked to unannounced IFS certification audit).',
    REGISTER_COID_TITLE: 'You will register for the following COID:',
    REGISTER_COID_TITLE_EXPLANATION:
        '(Be aware that your registration refers to the currently valid certificate so the IFS Food Safety Check will be performed within this certificate validity!)',
    REGISTER_COMPANY_BLOCKING_PERIOD: 'Optional: non production times and blocking period',
    REGISTER_COMPANY_BLOCKING_PERIOD_HELP:
        'The company can mention 10 operational days as blocking period. These 10 operational days can be split into maximum of 3 periods and shall consider company specific reasons like planned costumer visits, holidays, where the IFS Food Safety Check cannot taken place.',
    REGISTER_COMPANY_OPERATION_TIMES:
        'Usual operating and production times of the site to be audited',
    REGISTER_COMPANY_OPERATION_TIMES_DESC: '(e.g. Mon to Wed 9:00 – 14:00, 16:00 - 22:00)',
    REGISTER_COMPANY_OPERATION_TIMES_HELP:
        'These times will be forwarded by IFS Management to the IFS Food Safety Check auditor to ensure that the Check will be conducted at usual operating and production times.',
    REGISTER_COMPANY_SEASONAL_BLOCKING_PERIOD: 'If applicable, seasonal production period',
    REGISTER_COMPANYADDRESS_LANGUAGE: 'Company language',
    REGISTER_COMPANYADDRESS_TITLE: 'Name and address of the site of your company to be audited:',
    REGISTER_CONFIRM: 'send registration',
    REGISTER_CONTACT_DESCRIPTION:
        'Contact person of your company, who will be accompanying the auditor during IFS Food Safety Check:',
    REGISTER_CONTACT_EMAIL: 'E-Mail',
    REGISTER_CONTACT_NAME: 'First name Surname',
    REGISTER_CONTACT_PERSON: 'Person 1*',
    REGISTER_CONTACT_PERSON_OPT: 'Person 2',
    REGISTER_CONTACT_PHONE: 'Tel',
    REGISTER_CONTACT_SALUTATION: 'Form of address',
    REGISTER_CONTACT_SEND_MAIL_TO:
        'Please notice, that the advance invoice will be sent to the email address of person 1',
    REGISTER_FURTHER_DETAILS: 'Further information',
    REGISTER_INFORM_IF_CHANGES_1:
        'Please inform us about short-term changes regarding these periods when there is no production or operating processes (e.g. days where other certification procedures are taking place in your company, production downtime due to maintenance/ repair/ measures in pest control) via email: ',
    REGISTER_INFORM_IF_CHANGES_2: '.',
    REGISTER_NUMBER_EMPLOYEES: 'number of employees',
    REGISTER_ORDER_ACCEPT_COSTS:
        'Yes, I would like to place an order for an IFS Food Safety Check and I am aware that I will be liable to pay the arising costs. *',
    REGISTER_ORDER_ACCEPT_GENERALTERMS:
        'Yes, I accept the General Terms and Conditions (also available at www.ifs-certification.com) *',
    REGISTER_ORDER_IFSFOODCHECK: 'Order for an IFS Food Safety Check',
    REGISTER_ORDER_INFORMATION_1:
        'You will receive the invoice after completing the online order form and the respective final conclusion of this order. The invoice must be paid in advance.',
    REGISTER_ORDER_INFORMATION_2:
        'The IFS Food Safety Check is assigned only to one term of certificate validity and the order refers to the performance of one IFS Food Safety Check.',
    REGISTER_ORDER_INFORMATION_3: ' ',
    REGISTER_SALUTATION_FEMALE: 'Ms.',
    REGISTER_SALUTATION_MALE: 'Mr.',
    REGISTER_SUCCESS_ANOTHER_REGISTRATION_TEXT:
        'Do you want to order an IFS Food Safety Check for another COID?',
    REGISTER_SUCCESS_BUTTON_BACK: 'Back to IFS home',
    REGISTER_SUCCESS_BUTTON_REGISTER: 'Order',
    REGISTER_SUCCESS_GREETING: 'Kind regards, your IFS Team.',
    REGISTER_SUCCESS_INVOICE_TEXT:
        'An advance invoice will be sent to the email address of the mentioned contact person. Please settle the invoice within 14 working days.',
    REGISTER_SUCCESS_ON_HOLD_TEXT:
        'However, inconsistencies were found with regard to the framework conditions for commissioning the IFS Food Safety Check. We will check your case and will contact you shortly.',
    REGISTER_SUCCESS_ON_HOLD_TITLE: 'Thank you, your data entry was successful.',
    REGISTER_SUCCESS_TITLE: 'Thank you, your order for the COID :coid was successful!',
    REGISTER_TITLE: 'Please enter the following information:',
};
