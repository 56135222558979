import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';

// hocs
import withLocale from '../../../../hocs/withLocale';
// components
import ConfirmDialog from '../../../Common/ConfirmDialog';
import CertificateList from './CertificateList';

export const GET_COMPANY_CERTIFICATES = gql`
    query companyAsAdmin($coid: Int!) {
        companyAsAdmin(coid: $coid) {
            certificates {
                id
                hasRegistration
            }
        }
    }
`;

export const UPDATE_FSC_CERTIFICATE = gql`
    mutation updateFscCertificate($oldCeid: Int!, $newCeid: Int!) {
        updateFscCertificate(oldCeid: $oldCeid, newCeid: $newCeid) {
            newRegistrationstatus {
                id
                ceid
                status
                paid
                send_email
                createdAt
                confirmedAt
                hasReport
                company_blocking_period
                company_operation_times
                company_seasonal_blocking_period
                certificate_valid
                certificate_next_audit_from
                certificate_next_audit_to
                certificate_next_audit_date
                certificate_cb_responsible
            }
            errors {
                message
                extensions {
                    cause
                    message
                }
            }
        }
    }
`;

const CertificateChangeDialog = ({
    coid,
    currentCeid,
    isDialogVisible,
    onCancel,
    onSubmit,
    trans,
}) => {
    if (!isDialogVisible) {
        return null;
    }

    const [newCeid, setNewCeid] = useState(undefined);
    const handleSubmit = useCallback(
        mutation => {
            mutation({ variables: { oldCeid: currentCeid, newCeid } });
        },
        [currentCeid, newCeid]
    );

    return (
        <Query query={GET_COMPANY_CERTIFICATES} variables={{ coid }} fetchPolicy="no-cache">
            {({ data, loading, error }) => {
                if (error) {
                    return null;
                }
                const { companyAsAdmin } = data;
                return (
                    <Mutation
                        mutation={UPDATE_FSC_CERTIFICATE}
                        onCompleted={result =>
                            onSubmit(result.updateFscCertificate.newRegistrationstatus)
                        }
                    >
                        {(updateFscCertificate, { loading: submitting }) => (
                            <ConfirmDialog
                                open={isDialogVisible}
                                onConfirm={
                                    newCeid ? () => handleSubmit(updateFscCertificate) : undefined
                                }
                                onCancel={onCancel}
                                confirmButtonText={trans('CERTIFICATE_CHANGE_SUBMIT')}
                                loading={loading || submitting}
                                data-test={`ceidChangeDialog-${currentCeid}`}
                            >
                                <CertificateList
                                    loading={loading}
                                    selectedCeid={newCeid}
                                    onCeidChange={setNewCeid}
                                    certificates={companyAsAdmin ? companyAsAdmin.certificates : []}
                                />
                            </ConfirmDialog>
                        )}
                    </Mutation>
                );
            }}
        </Query>
    );
};

CertificateChangeDialog.propTypes = {
    coid: PropTypes.number.isRequired,
    currentCeid: PropTypes.number.isRequired,
    isDialogVisible: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    trans: PropTypes.func.isRequired,
};

export default withLocale(CertificateChangeDialog);
