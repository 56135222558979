import React from 'react';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';

import styles from '../styles.scss';

const TableHeaderCell = props => (
    <TableHeaderRow.Cell {...props} className={styles.tableHeaderCell} />
);

export default TableHeaderCell;
