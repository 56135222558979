import isEmpty from 'validator/lib/isEmpty';
import isEmail from 'validator/lib/isEmail';
import isInt from 'validator/lib/isInt';

export default (values, trans) => {
    const errors = {};

    if (!values.company_name || isEmpty(values.company_name)) {
        errors.company_name = () =>
            trans('VALIDATION_NOT_EMPTY', { field: trans('REGISTER_ADDRESS_COMPANYNAME') });
    } else if (!values.company_name.match(/[a-zA-ZäÄöÖüÜ]+/gm)) {
        errors.company_name = () =>
            trans('VALIDATION_MUST_CONTAIN_LETTER', {
                field: trans('REGISTER_ADDRESS_COMPANYNAME'),
            });
    }

    if (!values.legal_form || isEmpty(values.legal_form)) {
        errors.legal_form = () =>
            trans('VALIDATION_NOT_EMPTY', { field: trans('REGISTER_ADDRESS_LEGALFORM') });
    } else if (!values.legal_form.match(/[a-zA-ZäÄöÖüÜ]+/gm)) {
        errors.legal_form = () =>
            trans('VALIDATION_MUST_CONTAIN_LETTER', {
                field: trans('REGISTER_ADDRESS_LEGALFORM'),
            });
    }

    if (!values.street || isEmpty(values.street)) {
        errors.street = () =>
            trans('VALIDATION_NOT_EMPTY', { field: trans('REGISTER_ADDRESS_STREET') });
    }

    if (!values.zip || isEmpty(values.zip)) {
        errors.zip = () => trans('VALIDATION_NOT_EMPTY', { field: trans('REGISTER_ADDRESS_ZIP') });
    }

    if (!values.city || isEmpty(values.city)) {
        errors.city = () =>
            trans('VALIDATION_NOT_EMPTY', { field: trans('REGISTER_ADDRESS_CITY') });
    }

    if (!values.country || isEmpty(values.country)) {
        errors.country = () =>
            trans('VALIDATION_NOT_EMPTY', { field: trans('REGISTER_ADDRESS_COUNTRY') });
    }

    if (!values.vat || isEmpty(values.vat)) {
        errors.vat = () => trans('VALIDATION_NOT_EMPTY', { field: trans('REGISTER_ADDRESS_VAT') });
    }

    if (!values.language || isEmpty(values.language)) {
        errors.language = () =>
            trans('VALIDATION_NOT_EMPTY', { field: trans('REGISTER_COMPANYADDRESS_LANGUAGE') });
    }

    if (!values.number_employees || isEmpty(values.number_employees)) {
        errors.number_employees = () =>
            trans('VALIDATION_NOT_EMPTY', { field: trans('REGISTER_NUMBER_EMPLOYEES') });
    } else if (!isInt(values.number_employees, { min: 1, max: 10000000 })) {
        errors.number_employees = () =>
            trans('VALIDATION_NO_NUMBER', { field: trans('REGISTER_NUMBER_EMPLOYEES') });
    }

    if (!values.contact_salutation || isEmpty(values.contact_salutation)) {
        errors.contact_salutation = () =>
            trans('VALIDATION_NOT_EMPTY', { field: trans('REGISTER_CONTACT_SALUTATION') });
    }

    if (!values.contact_name || isEmpty(values.contact_name)) {
        errors.contact_name = () =>
            trans('VALIDATION_NOT_EMPTY', { field: trans('REGISTER_CONTACT_NAME') });
    }

    if (!values.contact_email || isEmpty(values.contact_email)) {
        errors.contact_email = () =>
            trans('VALIDATION_NOT_EMPTY', { field: trans('REGISTER_CONTACT_EMAIL') });
    } else if (!isEmail(values.contact_email)) {
        errors.contact_email = () => trans('VALIDATION_INVALID_EMAIL');
    }

    if (!values.contact_phone || isEmpty(values.contact_phone)) {
        errors.contact_phone = () =>
            trans('VALIDATION_NOT_EMPTY', { field: trans('REGISTER_CONTACT_PHONE') });
    }

    if (values.contact_email_opt && !isEmail(values.contact_email_opt)) {
        errors.contact_email_opt = () => trans('VALIDATION_INVALID_EMAIL');
    }

    if (!values.company_operation_times || isEmpty(values.company_operation_times)) {
        errors.company_operation_times = () =>
            trans('VALIDATION_NOT_EMPTY', { field: trans('REGISTER_COMPANY_OPERATION_TIMES') });
    }

    if (values.accept_costs !== true) {
        errors.accept_costs = () => trans('VALIDATE_ACCEPT_COSTS');
    }

    if (values.accept_AGBs !== true) {
        errors.accept_AGBs = () => trans('VALIDATE_ACCEPT_AGBS');
    }

    // if one field of the billing address is set all other ave to be set too
    if (
        values.billing_company_name ||
        values.billing_legal_form ||
        values.billing_street ||
        values.billing_zip ||
        values.billing_city ||
        values.billing_country ||
        values.billing_vat
    ) {
        if (!values.billing_company_name || isEmpty(values.billing_company_name)) {
            errors.billing_company_name = () => trans('VALIDATE_BILLING_ADDRESS_ALL_OR_NOTHING');
        } else if (!values.billing_company_name.match(/[a-zA-ZäÄöÖüÜ]+/gm)) {
            errors.billing_company_name = () =>
                trans('VALIDATION_MUST_CONTAIN_LETTER', {
                    field: trans('REGISTER_BILLINGADDRESS_COMPANY_NAME'),
                });
        }
        if (!values.billing_legal_form || isEmpty(values.billing_legal_form)) {
            errors.billing_legal_form = () => trans('VALIDATE_BILLING_ADDRESS_ALL_OR_NOTHING');
        } else if (!values.billing_legal_form.match(/[a-zA-ZäÄöÖüÜ]+/gm)) {
            errors.billing_legal_form = () =>
                trans('VALIDATION_MUST_CONTAIN_LETTER', {
                    field: trans('REGISTER_BILLINGADDRESS_LEGALFORM'),
                });
        }
        if (!values.billing_street || isEmpty(values.billing_street)) {
            errors.billing_street = () => trans('VALIDATE_BILLING_ADDRESS_ALL_OR_NOTHING');
        }
        if (!values.billing_zip || isEmpty(values.billing_zip)) {
            errors.billing_zip = () => trans('VALIDATE_BILLING_ADDRESS_ALL_OR_NOTHING');
        }
        if (!values.billing_city || isEmpty(values.billing_city)) {
            errors.billing_city = () => trans('VALIDATE_BILLING_ADDRESS_ALL_OR_NOTHING');
        }
        if (!values.billing_country || isEmpty(values.billing_country)) {
            errors.billing_country = () => trans('VALIDATE_BILLING_ADDRESS_ALL_OR_NOTHING');
        }
        if (!values.billing_vat || isEmpty(values.billing_vat)) {
            errors.billing_vat = () => trans('VALIDATE_BILLING_ADDRESS_ALL_OR_NOTHING');
        }
    }

    return errors;
};
