/**
 * COID Picker translations: fr
 * Please keep this file sorted alphabetically
 */
export default {
    COID_INPUT_LABEL: 'COID',
    COID_INPUT_TEXT: 'Veuillez entrer votre COID',
    COID_SUBMIT: 'Envoyer',
    COID_TITLE: 'Bonjour :username',
};
