import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import some from 'lodash/some';

import styles from '../styles.scss';

const TableRow = props => {
    const {
        tableRow: { rowId },
        row,
    } = props;
    const { updates, ...rowProps } = props;
    const statusChanged =
        updates && updates[rowId]
            ? some(updates[rowId].values, (value, key) => value !== row[key])
            : false;
    return (
        <Table.Row {...rowProps} className={classnames({ [styles.rowHighlight]: statusChanged })} />
    );
};

TableRow.propTypes = {
    row: PropTypes.shape({
        status: PropTypes.string.isRequired,
        paid: PropTypes.bool.isRequired,
    }).isRequired,
    tableRow: PropTypes.shape({
        rowId: PropTypes.number.isRequired,
    }).isRequired,
    updates: PropTypes.objectOf(PropTypes.shape({ values: PropTypes.shape({}) })).isRequired,
};

export default TableRow;
