/**
 * Validation translations: de
 * Please keep this file sorted alphabetically
 */
export default {
    MANDATORY_FIELD: '* Pflichtfeld',
    VALIDATE_ACCEPT_AGBS: 'Akzeptieren Sie bitte unsere allgemeinen Geschäftsbedingungen.',
    VALIDATE_ACCEPT_COSTS: 'Bestätigen Sie bitte die Kostenübernahme.',
    VALIDATE_BILLING_ADDRESS_ALL_OR_NOTHING:
        'Abweichende Rechnungsadresse muss vollständig ausfüllt oder vollständig leer gelassen werden',
    VALIDATION_CERTIFICATES_HAVE_REGISTRATION:
        'Für das aktuell gültige Zertifikat dieser COID liegt bereits eine Registrierung vor.',
    VALIDATION_INVALID_EMAIL: 'Keine gültige E-Mail-Adresse',
    VALIDATION_MUST_CONTAIN_LETTER: ':field muss mindestens einen Buchstaben enthalten',
    VALIDATION_NO_CERTIFICATE: 'Für diese COID sind keine gültigen Zertifikate vorhanden',
    VALIDATION_NO_COMPANY_FOR_COID: ':field ist keine gültige COID',
    VALIDATION_NO_EMAIL: 'Bitte geben sie eine gültige Email Adresse an',
    VALIDATION_NO_NUMBER: ':field muss eine Zahl sein',
    VALIDATION_NO_POSTALCODE: ':field muss eine gültige PLZ enthalten',
    VALIDATION_NOT_EMPTY: ':field ist ein Pflichtfeld',
};
