import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

// hocs
import withLocale from '../../../../hocs/withLocale';
// styles
import styles from '../../styles.scss';

const CertificateList = ({ certificates, onCeidChange, selectedCeid, loading, trans }) => {
    const availableCerts = certificates.filter(cert => !cert.hasRegistration);
    return (
        <Fragment>
            {availableCerts.length ? trans('CERTIFICATE_AVAILABLE_CERTIFICATES') : undefined}
            {!loading && !availableCerts.length
                ? trans('CERTIFICATE_NO_AVAILABLE_CERTIFICATES')
                : undefined}
            <List component="nav" className={styles.certificateList}>
                {availableCerts.map(({ id }) => (
                    <ListItem
                        button
                        key={id}
                        onClick={() => onCeidChange(id)}
                        className={classNames({
                            [styles.selected]: selectedCeid === id,
                        })}
                        data-test={`certificateListItem-${id}`}
                    >
                        <ListItemText>{id}</ListItemText>
                    </ListItem>
                ))}
            </List>
        </Fragment>
    );
};

CertificateList.propTypes = {
    certificates: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            hasRegistration: PropTypes.bool.isRequired,
        })
    ).isRequired,
    loading: PropTypes.bool.isRequired,
    onCeidChange: PropTypes.func.isRequired,
    selectedCeid: PropTypes.number,
    trans: PropTypes.func.isRequired,
};

CertificateList.defaultProps = {
    selectedCeid: undefined,
};

export default withLocale(CertificateList);
