/**
 * Registerform translations: it
 * Please keep this file sorted alphabetically
 */
export default {
    ifs_cashcarry2: 'IFS Wholesale 2',
    ifs_food6: 'IFS Food 6.1',
    ifs_food7: 'IFS Food 7',
    ifs_food8: 'IFS Food 8',
    REGISTER_ADDRESS_CITY: 'Città',
    REGISTER_ADDRESS_COMPANYNAME: 'Nome',
    REGISTER_ADDRESS_COUNTRY: 'Nazione',
    REGISTER_ADDRESS_LEGALFORM: 'Denominazione legale',
    REGISTER_ADDRESS_STREET: 'Indirizzo, N',
    REGISTER_ADDRESS_VAT: 'IVA',
    REGISTER_ADDRESS_ZIP: 'Codice avviamento postale',
    REGISTER_BILLINGADDRESS_COMPANY_NAME: 'Nome',
    REGISTER_BILLINGADDRESS_LEGALFORM: 'Denominazione legale',
    REGISTER_BILLINGADDRESS_TITLE: 'Se pertinente, indirizzo diverso dello stabilimento',
    REGISTER_CEID: 'Standard',
    REGISTER_CEID_TITLE:
        'Per favore, scegliere il certificate IFS pertinente per il quale deve essere svolto l’IFS Food Safety Check:',
    REGISTER_CEID_VALID_TO: 'valido da :date',
    REGISTER_COID_HINT:
        "Registrazione per l'IFS Food Safety Check (NON si tratta di un audit di certificazione IFS non annunciato).",
    REGISTER_COID_TITLE: 'Lei si registrerà per il seguente COID',
    REGISTER_COID_TITLE_EXPLANATION:
        '(Per favore, consideri che la sua registrazione si riferisce al certificato attualmente valido, quindi l’IFS Food Safety Check sarà eseguito entro la validità di questo certificato!)',
    REGISTER_COMPANY_BLOCKING_PERIOD: 'Opzionale: periodo non produttivo e periodo di blocco',
    REGISTER_COMPANY_BLOCKING_PERIOD_HELP:
        'L’azienda può indicare 10 giornate lavorative come periodo di blocco. Queste 10 giornate lavorative possono essere divise in un massimo di 3 periodi e devono considerare specifiche motivazioni aziendali per le quali l’IFS Food Safety Check non può essere svolto: quali visite dei clienti pianificate, vacanze personali del responsabile esecutivo.',
    REGISTER_COMPANY_OPERATION_TIMES: 'Consueto orario di lavoro del sito da auditare',
    REGISTER_COMPANY_OPERATION_TIMES_DESC: '(es: LUN a MER 9:00 – 14:00, 16:00 - 22:00)',
    REGISTER_COMPANY_OPERATION_TIMES_HELP:
        'Questo orario sarà inviato da IFS Managment all’auditor incaricato per l’Food Safety Check per garantire che il controllo avvenga nell’orario di lavoro aziendale corretto.',
    REGISTER_COMPANY_SEASONAL_BLOCKING_PERIOD: 'Se pertinente, periodo di produzione stagionale',
    REGISTER_COMPANYADDRESS_LANGUAGE: 'lingua parlata in azienda',
    REGISTER_COMPANYADDRESS_TITLE: 'Nome e indirizzo del sito dell’azienda oggetto di audit',
    REGISTER_CONFIRM: 'Inviare la registrazione',
    REGISTER_CONTACT_DESCRIPTION:
        'Persona di contatto nella sua azienda, che accompagnerà l’auditor durante l’IFS Food Safety Check',
    REGISTER_CONTACT_EMAIL: 'E-mail',
    REGISTER_CONTACT_NAME: 'Nome Cognome',
    REGISTER_CONTACT_PERSON: 'Persona 1*',
    REGISTER_CONTACT_PERSON_OPT: 'Persona 2',
    REGISTER_CONTACT_PHONE: 'Tel',
    REGISTER_CONTACT_SALUTATION: '(Sig./Sig.ra)',
    REGISTER_CONTACT_SEND_MAIL_TO:
        'Nota: la fattura anticipata sarà inviata all’indirizzo email della persona 1',
    REGISTER_FURTHER_DETAILS: 'Ulteriori informazioni',
    REGISTER_INFORM_IF_CHANGES_1: 'Informare IFS via email a',
    REGISTER_INFORM_IF_CHANGES_2:
        ' in breve tempo circa qualsiasi cambiamento di questi periodi quando non c’è produzione o processi operativi (es. giornate pianificate per altri schemi di certificazione, fermi di produzione per manutenzione/riparazione/disinfestazione).',
    REGISTER_NUMBER_EMPLOYEES: 'Numero dei didpendenti',
    REGISTER_ORDER_ACCEPT_COSTS:
        'Si, vorrei registrare un ordine per l’IFS Food Safety Check e sono informato che sarò responsabile di pagare i costi derivanti. *',
    REGISTER_ORDER_ACCEPT_GENERALTERMS:
        'Si, accetto I termini Generali di Contratto (disponibile su www.ifs-certification.com) *',
    REGISTER_ORDER_IFSFOODCHECK: 'Ordine per un IFS Food Safety Check',
    REGISTER_ORDER_INFORMATION_1:
        'Lei riceverà la fattura dopo aver completato e finalizzato l’ordine on line. La fattura deve essere pagata in anticipo.',
    REGISTER_ORDER_INFORMATION_2:
        'L’ IFS Food Safety Check è assegnato ad un solo termine di validità del certificato IFS e l’ordine si riferisce allo svolgimento di 1 (uno) IFS Food Safety Check.',
    REGISTER_ORDER_INFORMATION_3: ' ',
    REGISTER_SALUTATION_FEMALE: 'Sig.ra',
    REGISTER_SALUTATION_MALE: 'Sig.',
    REGISTER_SUCCESS_ANOTHER_REGISTRATION_TEXT:
        'Vuole ordinare un IFS Food Safety Checks per un altro COID?',
    REGISTER_SUCCESS_BUTTON_BACK: 'Ritornare a IFS home',
    REGISTER_SUCCESS_BUTTON_REGISTER: 'Ordine',
    REGISTER_SUCCESS_GREETING: 'Cordiali saluti, il Team IFS',
    REGISTER_SUCCESS_INVOICE_TEXT:
        'Una fattura anticipata verrà inviata all’indirizzo e-mail della persona di contatto menzionata. Si prega di saldare la fattura entro 14 giorni lavorativi.',
    REGISTER_SUCCESS_ON_HOLD_TEXT:
        'Tuttavia sono state rilevate difformità riguardanti le condizioni contrattuali per l’incarico dell’ IFS Food Safety Check. Controlleremo il suo caso e la contatteremo a breve.',
    REGISTER_SUCCESS_ON_HOLD_TITLE: 'Grazie, I suoi dati sono stati inseriti.',
    REGISTER_SUCCESS_TITLE: 'Grazie, il vostro ordine per il COID :coid ha avuto successo!',
    REGISTER_TITLE: 'Inserire le seguenti informazioni:',
};
